/* eslint-disable camelcase */
import 'moment/locale/nl'
const moment = require('moment')

export function mapToCardLead ({
  contract_name,
  form_received_at,
  status,
  card_number,
  name,
  company_name,
  phone_number,
  email
}) {
  return {
    contractName: contract_name,
    formReceivedAt: moment(form_received_at).locale('nl').format('DD-MM-YYYY, h:mm:ss'),
    status: status,
    cardNumber: card_number,
    name: name,
    companyName: company_name,
    phoneNumber: phone_number,
    email: email
  }
}
