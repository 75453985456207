/* eslint-disable camelcase */
import { AbstractService } from '../abstract'
import 'moment/locale/nl'
import { mapToSideOperation } from '@/business/frontend/SideOperation'

class SideOperationService extends AbstractService {
  getOperations (params) {
    return this.getData(({ startDate, stopDate, tokens, paging }) => {
      const { sortingDir, sortBy, page, size } = paging
      const sort = sortBy ? `${sortingDir === 0 ? '-' : ''}${sortBy}` : ''
      return this.http.get('/public_transport_and_parking_transactions/', {
        params: {
          start__gte: startDate ? startDate.split('Z')[0] : null,
          start__lte: stopDate ? stopDate.split('Z')[0] : null,
          card__visible_number: tokens && tokens.length ? tokens.join(',') : '',
          limit: page ? page * size : size,
          offset: page ? size : 0,
          sort
        }
      }).then(response => {
        const data = response.data
        const results = data.results && data.results.length ? data.results.map(mapToSideOperation) : []
        return {
          data: results,
          hasNextPage: !!data.next
        }
      }).catch(err => {
        console.error(err)
        throw err
      })
    }, params)
  }
}

export const sideOperationService = new SideOperationService({ baseURL: '/mobility2/secured' })
