export const DashboardThemes = {
  LIGHT: 'light',
  DARK: 'dark'
}

Object.freeze(DashboardThemes)

export default {
  dashboardTheme: DashboardThemes.DARK,
  dashboardTabIndex: 0
}
