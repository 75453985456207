<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center mx-1 mb-2">
      <h4 class="font-weight-normal">
        {{ title }}
      </h4>
      <div class="right-block">
        <label>{{ $t('leads.statuses.title') }}:
          <select v-model="selectedStatus" class="dropdown-filter" @change="filterByStatus">
            <option value="">
              {{ $t('leads.statuses.non') }}
            </option>
            <option value="IN_PROGRESS">
              {{ $t('leads.statuses.inProgress') }}
            </option>
            <option value="WON">
              {{ $t('leads.statuses.won') }}
            </option>
            <option value="LOST">
              {{ $t('leads.statuses.lost') }}
            </option>
          </select>
        </label>
        <export-to-excel-button :loading="excelLoading" class="export-button" @click="export2Excel" />
        <a v-if="leadsLink" :href="leadsLink" target="_blank">
          <primary-button> {{ $t('leads.submit') }} </primary-button>
        </a>
      </div>
    </b-row>
    <b-row>
      <b-col xs="12">
        <div class="pb-xlg h-100">
          <div class="table-responsive">
            <v-server-table
              ref="table"
              class="leads-table"
              :columns="columns"
              :options="options"
            >
              <template slot="buyOrLease" slot-scope="props">
                {{ getTypeTemplate(props.row.buyOrLease) }}
              </template>
              <template slot="status" slot-scope="props">
                {{ getStatusTemplate(props.row.status) }}
              </template>
              <template slot="productChoice" slot-scope="props">
                {{ getProductsTemplate(props.row.productChoice) }}
              </template>
            </v-server-table>
            <div v-if="loading" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
              <b-spinner class="fetching-indicator" type="grow" label="Spinning" />
            </div>
            <b-button
              v-if="showNextPage"
              class="center load-more-button"
              @click="loadAdditionalData()"
            >
              {{ $t('pages.common.loadMore') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ExportToExcelButton from '@/components/ExportToExcelButton/ExportToExcelButton'
import { getBrandingForCurrentLabel } from '@/utils/branding-helper'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

export default {
  name: 'LeadsTable',
  components: {
    ExportToExcelButton,
    PrimaryButton
  },
  props: ['title'],
  data () {
    return {
      leadsLink: '',
      selectedStatus: '',
      columns: [
        'dealerLocation',
        'advisorName',
        'leadName',
        'contractNumber',
        'buyOrLease',
        'createdAt',
        'inspectionFormSent',
        'inspectionFormReceived',
        'offerSent',
        'status',
        'contractDate',
        'productChoice'
      ],
      headings: {
        dealerLocation: this.$t('leads.dealerLocation'),
        advisorName: this.$t('leads.advisorName'),
        leadName: this.$t('leads.leadName'),
        contractNumber: this.$t('leads.contractNumber'),
        buyOrLease: this.$t('leads.buyOrLease'),
        createdAt: this.$t('leads.createdAt'),
        inspectionFormSent: this.$t('leads.inspectionFormSent'),
        inspectionFormReceived: this.$t('leads.inspectionFormReceived'),
        offerSent: this.$t('leads.offerSent'),
        status: this.$t('leads.status'),
        contractDate: this.$t('leads.contractDate'),
        productChoice: this.$t('leads.productChoice')
      },
      sortable: [
        'dealerLocation',
        'advisorName',
        'leadName',
        'contractNumber',
        'buyOrLease',
        'createdAt',
        'inspectionFormSent',
        'inspectionFormReceived',
        'offerSent',
        'status',
        'contractDate'
      ],
      linesCount: 25,
      loading: false,
      excelLoading: false,
      showNextPage: false
    }
  },
  computed: {
    ...mapState('auth', ['relationId']),
    ...mapGetters('filtering', ['getDateFilter']),
    options () {
      return {
        headings: this.headings,
        columnsDisplay: {
          advisorName: 'min_desktop',
          buyOrLease: 'min_desktop',
          createdAt: 'min_desktop',
          inspectionFormSent: 'min_desktop',
          inspectionFormReceived: 'min_desktop',
          offerSent: 'min_desktop',
          contractDate: 'min_desktop',
          productChoice: 'min_desktop'
        },
        sortable: this.sortable,
        cellClasses: {
          createdAt: [{ class: 'date-column', condition: () => true }],
          inspectionFormSent: [{ class: 'date-column', condition: () => true }],
          inspectionFormReceived: [{ class: 'date-column', condition: () => true }],
          offerSent: [{ class: 'date-column', condition: () => true }],
          contractDate: [{ class: 'date-column', condition: () => true }]
        },
        orderBy: {
          column: 'createdAt',
          ascending: false
        },
        filterable: false,
        pagination: { chunk: 25, dropdown: false },
        perPageValues: [],
        perPage: 1000,
        // this parameter we need for hide dafault pagination panel
        texts: {
          limit: '',
          count: '',
          noResults: this.$t('pages.common.noResults'),
          loading: this.$t('pages.common.loading')
        },
        skin: 'table',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        },
        requestAdapter: data => {
          return {
            page: data.page - 1,
            size: this.linesCount,
            sortBy: this.getSortByForColumn(data.orderBy),
            sortingDir: data.ascending
          }
        },
        requestFunction: this.loadData
      }
    }
  },
  mounted () {
    getBrandingForCurrentLabel()
      .then(data => (this.leadsLink = data.leadsLink))
  },
  methods: {
    getSortByForColumn (column) {
      switch (column) {
        case 'dealerLocation':
          return 'dealer_location'
        case 'advisorName':
          return 'sales_advisor'
        case 'leadName':
          return 'last_name'
        case 'contractNumber':
          return 'external_contract_number'
        case 'buyOrLease':
          return 'buy_or_lease'
        case 'createdAt':
          return 'created_at'
        case 'inspectionFormSent':
          return 'inspection_form_sent_at'
        case 'inspectionFormReceived':
          return 'inspection_form_received_at'
        case 'offerSent':
          return 'offer_sent_at'
        case 'status':
          return 'status'
        case 'contractDate':
          return 'quotes__contract__start_date'
        case 'productChoice':
          return 'quotes__product_choice'
      }
    },
    getStatusTemplate (initialStatus) {
      switch (initialStatus) {
        case 'WON':
          return this.$t('leads.statuses.won')
        case 'LOST':
          return this.$t('leads.statuses.lost')
        default:
          return this.$t('leads.statuses.inProgress')
      }
    },
    getTypeTemplate (initialType) {
      switch (initialType) {
        case 'BUY':
          return this.$t('leads.types.buy')
        case 'LEASE':
          return this.$t('leads.types.lease')
        default:
          return initialType
      }
    },
    getProductsTemplate (initialProducts) {
      switch (initialProducts) {
        case 'NONE':
          return this.$t('leads.products.none')
        case 'ONLY_CHARGE_POINT':
          return this.$t('leads.products.chargePoint')
        case 'ONLY_CARD':
          return this.$t('leads.products.card')
        case 'CHARGE_POINT_AND_CARD':
          return this.$t('leads.products.chargePointAndCard')
        default:
          return initialProducts
      }
    },
    filterByStatus () {
      this.$refs.table.refresh()
    },
    loadData (data) {
      if (this.loading) {
        return Promise.resolve({
          count: 0,
          data: []
        })
      }
      this.loading = true
      return this.$leadsService.getLeads({
        paging: data,
        status: this.selectedStatus
      }).then(response => {
        this.showNextPage = response.hasNextPage
        response.count = response.data.length || 0
        if (response.count) {
          this.$emit('show')
        }
        // res.count should mutate here for avoid showing default pagination panel
        return response
        // eslint-disable-next-line no-console
      }).catch(err => {
        console.error(err)
        return {
          count: 0,
          data: []
        }
      })
        .finally(() => (this.loading = false))
    },
    loadAdditionalData () {
      this.linesCount += 25
      this.$refs.table.refresh()
    },
    export2Excel () {
      const filters = {}
      if (this.getDateFilter.startDate) {
        filters['created_at__gte'] = this.getDateFilter.startDate.split('T')[0]
      }
      if (this.getDateFilter.stopDate) {
        filters['created_at__lte'] = this.getDateFilter.stopDate.split('T')[0]
      }
      const options = {
        model: 'opportunity',
        fileName: `${this.title}.xlsx`,
        fields: [
          'dealer_location',
          'sales_advisor',
          'name',
          'external_contract_number',
          'buy_or_lease',
          'created_at',
          'inspection_form_sent_at',
          'inspection_form_received_at',
          'offer_sent_at',
          'status',
          'quotes.first.contract.start_date',
          'quotes.first.product_choice'
        ],
        headings: Object.values(this.headings),
        filters
      }
      this.excelLoading = true
      this.$exportService.exportToExcel(options)
        .finally(() => (this.excelLoading = false))
    }
  }
}
</script>

<style lang="scss">
.leads-table {
  th {
    white-space: nowrap !important;
    .VueTables__sort-icon {
      float: none;
      margin-left: 15px;
    }
  }
}
</style>
<style scoped lang="scss">
.right-block {
  display: flex;

  .export-button {
    margin-left: 15px;
    height: max-content;
  }
  a {
    margin-left: 15px;
  }
}
a:hover {
  text-decoration: unset;
}
.dropdown-filter {
  border: var(--navbar-border);
  padding: 0.3rem;
  cursor: pointer;
  margin-left: 5px;
}
</style>
