<template>
  <form class="w-100 display-flex flex-column align-items-center" @submit.prevent="forgotPassword">
    <div class="form-group w-100">
      <label for="username" class="label"> {{ $t('pages.auth.username') }}</label>
      <input
        id="username"
        ref="username"
        class="form-control no-border"
        required
        type="text"
        name="username"
        autocomplete="off"
      >
    </div>

    <b-button
      class="auth-button"
      type="submit"
      size="sm"
      variant="inverse"
      :disabled="inProgress"
    >
      <span>{{ $t('pages.auth.forgot-password.submit') }}</span>
      <b-spinner v-if="inProgress" small type="grow" />
    </b-button>
  </form>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('auth', ['inProgress'])
  },
  methods: {
    forgotPassword () {
      this.$gtag.event('forgotPassword', { method: 'Google' })
      this.$store.dispatch('auth/forgotPassword', this.$refs.username.value.toLowerCase())
    }
  }
}
</script>

<style src="../Auth.scss" lang="scss" scoped/>
