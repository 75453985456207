/* eslint-disable camelcase */

export function mapToInstallations ({
  dealer_location,
  sales_advisor,
  driver_name,
  external_contract_number,
  contract_start_date,
  charge_point_delivery_date,
  charge_point_delivery_date_communicated,
  charge_point_order_date,
  charge_point_installation_date
}) {
  return {
    dealerLocation: dealer_location,
    advisorName: sales_advisor,
    driverName: driver_name,
    contractNumber: external_contract_number,
    contractDate: contract_start_date,
    cpDeliveryDate: charge_point_delivery_date,
    cpDeliveryDateCommunicated: charge_point_delivery_date_communicated,
    cpOrdered: charge_point_order_date,
    cpInstallationDate: charge_point_installation_date
  }
}
