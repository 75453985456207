<template>
  <div>
    <b-navbar class="header d-print-none" sticky>
      <b-navbar-brand href="/">
        <img class="branded-logo" :src="logoImg">
      </b-navbar-brand>
      <b-nav class="header-dropdown">
        <b-nav-item-dropdown
          class="mr-2 header-dropdown-item"
          extra-menu-classes="py-0"
          right
          no-caret
        >
          <template #button-content>
            <span v-if="userGivenName">{{ $t('pages.common.welcomeMessageName', {givenName: userGivenName}) }}</span>
            <span v-if="!userGivenName">{{ $t('pages.common.welcomeMessage') }}</span>
            <i class="fa fa-chevron-down ml-2" />
          </template>
          <b-dropdown-item v-if="$route.name === 'MySettings'" @click="goToDashboard">
            {{ $t('pages.emobility.title') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="$route.name === 'MobilityPage'" @click="goToMySettings">
            {{ $t('pages.mySettings.title') }}
          </b-dropdown-item>
          <b-dropdown-item @click="logoutClicked">
            <span class="d-sm-inline">{{ $t('pages.common.logout') }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>
    </b-navbar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getBrandingForCurrentLabel } from '@/utils/branding-helper'
import { labelId } from '@/config'
import router, { loginPath } from '@/Routes'

export default {
  name: 'Header',
  components: { },
  data: () => ({
    logoImg: null,
    brand: labelId ? labelId.toLowerCase() : ''
  }),
  computed: {
    ...mapState('layout', {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic
    }),
    ...mapState('auth', ['userGivenName'])
  },
  mounted () {
    getBrandingForCurrentLabel().then(style => (this.logoImg = require(`../../assets/label/${style.headerLogo || style.logo}`)))
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar', 'switchSidebar', 'changeSidebarActive']),
    ...mapActions('auth', ['logOut']),
    switchSidebarMethod () {
      if (!this.sidebarClose) {
        this.switchSidebar(true)
        this.changeSidebarActive(null)
      } else {
        this.switchSidebar(false)
        const paths = this.$route.fullPath.split('/')
        paths.pop()
        this.changeSidebarActive(paths.join('/'))
      }
    },
    goToDashboard () {
      router.push('/', () => {})
    },
    goToMySettings () {
      router.push('/my-settings', () => {})
    },
    logoutClicked () {
      this.$messageService.hideAllMessages()
      this.logOut().finally(() => {
        router.push(loginPath)
      })
    }
  }
}
</script>

<style lang="scss">
.greennl {
  .btn {
    color: var(--label-color-primary-dark);

    &:hover {
      color: var(--label-color-secondary);
    }
  }
}

.porsche {
  .dropdown-menu.dropdown-menu {
    box-shadow: none;
  }

  .branded-logo {
    max-height: 60px !important;
  }
}

.dropdown-menu.dropdown-menu.dropdown-menu {
  border: var(--navbar-border);
  border-radius: 0;

  a {
    transition: all 0.15s ease-in-out;

    &:hover, &:active {
      background-color: var(--label-login-button-background);
      color: var(--label-color-text-secondary);
    }
  }
}

.brandstof {
  .dropdown-menu.dropdown-menu.dropdown-menu {
    a:hover, a:active {
      color: #FFFFFF;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../../styles/app';

.header {
  z-index: 100;
  background: var(--navbar-bg);
  box-shadow: var(--navbar-shadow);
  border-bottom: var(--navbar-border);
  padding: 0 1rem 0;

  @include media-breakpoint-up(md) {
    padding: 0 1.85rem 0;
  }

  .navbar-brand {
    padding: 0;

    @include media-breakpoint-up(md) {
      margin: auto;
    }

    .branded-logo {
      max-width: 200px;
      max-height: 32px;
    }
  }

  &-dropdown {
    display: block;
    position: absolute;
    right: 1rem;

    &-item {
      height: 37px;
      top: 10px;
      border: var(--navbar-border);

      span {
        line-height: 1;
      }

      .fa {
        margin-top: -3px;
      }
    }
  }
}

.navbarBrand {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  pointer-events: none;

  i {
    font-size: 10px;
  }
}

.notificationsMenu {
  .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    top: $navbar-height !important;
  }
}

.settingsDropdown {
  .dropdown-item:focus {
    outline: none;
  }
}

.notificationsWrapper {
  width: min-content;
}
</style>
