import axios from 'axios'
import { mapToChargingTokens } from '@/business/frontend/ChargingToken'
import { refreshSessionAndCall, retryExpired } from '@/utils/retryer'

let http = null
let authorized = false

export const tokenService = {
  get authorized () {
    return authorized
  },

  init ({ token = null }) {
    http = axios.create({
      baseURL: '/mobility2/secured',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    this.setToken({ token })
  },

  setToken ({ token }) {
    if (token?.jwtToken) {
      http.defaults.headers.common.Authorization = `Bearer ${token.jwtToken}`
      authorized = true
    } else {
      // eslint-disable-next-line no-console
      console.warn('You tried to set the authorization token without providing one!')
    }
  },

  removeToken () {
    http.defaults.headers.common.Authorization = ''
    authorized = false
  },

  getTokens ({
    paging: {
      page,
      size,
      sort: {
        sortBy,
        sortingDir
      }
    }
  } = {
    paging: {
      page: 0,
      size: 100,
      sort: {
        sortBy: 'visible_number',
        sortingDir: 1
      }
    }
  }) {
    if (!http?.defaults?.headers?.common?.Authorization) {
      return Promise.reject('No authorization header is set in the token service')
    }
    return retryExpired({
      callback: getTokensCallback(page, size, sortBy, sortingDir),
      number: 3,
      wait: 1000
    })
  }
}

const getTokensCallback = (page, size, sortBy, sortingDir) => {
  const callback = onRetry => {
    if (onRetry) {
      return refreshSessionAndCall(callback)
    } else {
      const sort = `${sortingDir === 0 ? '-' : ''}${sortBy}`
      return http.get('/cards/', {
        params: {
          limit: page ? page * size : size,
          offset: page ? size : 0,
          sort
        }
      })
        .then(response => {
          const _data = mapToChargingTokens(response)
          return Promise.resolve({
            data: _data,
            count: _data.count,
            hasNextPage: response?.data?.next !== null
          })
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
          throw error // Important to re-throw so the catch in the retry fn can pick it up
        })
    }
  }

  return callback
}

if (process.env.VUE_APP_USE_REAL_SERVICES === 'true') {
  window.tokens = tokenService
}
