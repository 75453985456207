<template>
  <button
    v-if="isVisible"
    class="go-top"
    @click="backToTop"
  >
    <i class="fa fa-angle-up" aria-hidden="true" />
  </button>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    visibleOffset: {
      type: [String, Number],
      default: 600
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  mounted () {
    window.smoothScroll = () => {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothScroll)
        window.scrollTo(0, 0)
      }
    }
    window.addEventListener('scroll', this.catchScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.catchScroll)
  },
  methods: {
    catchScroll () {
      this.isVisible = (window.pageYOffset > parseInt(this.visibleOffset))
    },
    backToTop () {
      window.smoothScroll()
    }
  }
}
</script>

<style scoped lang="scss">

.go-top {
  border-radius: 5px;
  background-color: rgb(217, 230, 243);
  background-color: rgba(1, 14, 27, .7);
  position: fixed;
  width: 45px;
  height: 45px;
  display: block;
  right: 15px;
  bottom: 15px;
  border: none;
  opacity: .8;
  z-index: 4;
  transition: all .4s ease-in;
  .fa {
    color: white;
    font-size: 22px;
  }
  &:hover {
    opacity: 1;
    background-color: rgb(1,14,27);
    background-color: rgba(1, 14, 27, .9);
  }
}

</style>
