import Vue from 'vue'
import store from '@/store'

export default {
  async getAccountDetails ({ commit }) {
    try {
      const accountId = store.state.auth.accountId
      const response = await Vue.$settingsService.getAccountDetails({ accountId })
      const account = response?.data
      commit('setAccountEmail', account.email)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }
}
