/* eslint-disable camelcase */

export function mapToLead ({
  dealer_location,
  sales_advisor,
  lead_name,
  external_contract_number,
  buy_or_lease,
  created_at,
  inspection_form_sent_at,
  inspection_form_received_at,
  offer_sent_at,
  status,
  contract_start_date,
  product_choice
}) {
  return {
    dealerLocation: dealer_location,
    advisorName: sales_advisor,
    leadName: lead_name,
    contractNumber: external_contract_number,
    buyOrLease: buy_or_lease,
    createdAt: created_at,
    inspectionFormSent: inspection_form_sent_at,
    inspectionFormReceived: inspection_form_received_at,
    offerSent: offer_sent_at,
    status: status,
    contractDate: contract_start_date,
    productChoice: product_choice
  }
}
