import axios from 'axios'
import { mapToTransactions, mapToSessions, mapToMetrics } from '@/business/frontend/Transaction'
import { refreshSessionAndCall, retryExpired } from '@/utils/retryer'

let http = null
let authorized = false

export const transactionService = {
  get authorized () {
    return authorized
  },

  init ({ token = null }) {
    http = axios.create({
      baseURL: '/mobility2/secured',
      headers: { 'Content-Type': 'application/json' }
    })
    this.setToken({ token })
  },

  setToken ({ token }) {
    if (token?.jwtToken) {
      http.defaults.headers.common.Authorization = `Bearer ${token.jwtToken}`
      authorized = true
    } else {
      // eslint-disable-next-line no-console
      console.warn('You tried to set the authorization token without providing one!')
    }
  },

  removeToken () {
    http.defaults.headers.common.Authorization = ''
    authorized = false
  },

  getTransactions ({
    startDate,
    stopDate,
    tokens,
    paging: { page, size, sort: { sortBy, sortingDir } } = { page: 0, size: 25, sort: { sortBy: 'start_date', sortingDir: 1 } }
  }) {
    if (!http?.defaults?.headers?.common?.Authorization) {
      return Promise.reject(new Error('No authorization header is set in the transaction service'))
    }
    return retryExpired({
      callback: getTransactionsCallback({ startDate, stopDate, tokens, page, size, sortBy, sortingDir }),
      number: 3,
      wait: 1000
    })
  },

  getSessions ({ startDate, stopDate, paging: { page, size, sort: { sortBy, sortingDir } } }) {
    if (!http?.defaults?.headers?.common?.Authorization) {
      return Promise.reject(new Error('No authorization header is set in the session service'))
    }

    return retryExpired({
      callback: getSessionsCallback({ startDate, stopDate, page, size, sortBy, sortingDir }),
      number: 3,
      wait: 1000
    })
  }
}

const getTransactionsCallback = ({ startDate, stopDate, tokens, page, size, sortBy, sortingDir }) => {
  const callback = onRetry => {
    if (onRetry) {
      return refreshSessionAndCall(callback)
    } else {
      const sort = `${sortingDir === 0 ? '-' : ''}${sortBy}`
      const endPoint = '/cdrs/'
      return http.get(`${endPoint}`, {
        params: {
          start_date: startDate ? startDate.split('T')[0] : null,
          end_date: stopDate ? stopDate.split('T')[0] : null,
          limit: page ? page * size : size,
          offset: page ? size : 0,
          tokens: tokens && tokens.length ? tokens.join(',') : '',
          sort
        }
      })
        .then(transactionsResponse => {
          const _data = mapToTransactions(transactionsResponse)
          return {
            data: _data,
            count: _data.count,
            hasNextPage: transactionsResponse?.data?.next !== null,
            totals: transactionsResponse?.data?.totals,
            metrics: mapToMetrics(transactionsResponse?.data?.metrics)
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
          throw error // Important to re-throw so the catch in the retry fn can pick it up
        })
    }
  }

  return callback
}

const getSessionsCallback = ({ startDate, stopDate, page, size, sortBy, sortingDir }) => {
  const callback = onRetry => {
    if (onRetry) {
      return refreshSessionAndCall(callback)
    } else {
      const sort = `${sortingDir === 0 ? '-' : ''}${sortBy}`
      const endPoint = '/session/'
      return http.get(`${endPoint}`, {
        params: {
          start_date: startDate ? startDate.split('T')[0] : null,
          end_date: stopDate ? stopDate.split('T')[0] : null,
          limit: page ? page * size : size,
          offset: page ? size : 0,
          sort
        }
      })
        .then(transactionsResponse => {
          const _data = mapToSessions(transactionsResponse)
          return {
            data: _data,
            count: _data.count,
            hasNextPage: transactionsResponse?.data?.next !== null,
            totals: transactionsResponse?.data?.totals,
            metrics: transactionsResponse?.data?.metrics
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
          throw error // Important to re-throw so the catch in the retry fn can pick it up
        })
    }
  }

  return callback
}

if (process.env.VUE_APP_USE_REAL_SERVICES === 'true') {
  window.transactions = transactionService
}
