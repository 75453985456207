<template>
  <b-badge :variant="statusVariant">
    {{ data.i18nStatus }}
  </b-badge>
</template>

<script>

export default {
  name: 'StatusCell',
  props: ['data', 'index'],
  computed: {
    statusVariant () {
      if (this.data.status === 'INACTIVE') {
        return 'warning'
      } else {
        return 'success'
      }
    }
  }
}
</script>
