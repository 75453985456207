<template>
  <div class="login-container" :class="brand">
    <language-select class="lang-select" />
    <!-- <div class="login-form shadow-lg">
      <language-select class="lang-select" />
      <div class="top-section">
        <img :src="logoImg">
        <div class="label">
          <h2>
            <span>{{ $t(`pages.auth.${currentRoute}.title`) }}</span>
          </h2>
          <div class="auth-tip">
            {{ $t(`pages.auth.${currentRoute}.tip`) }}
          </div>
        </div>
      </div>
      <div class="bottom-section">
        <router-view />
      </div>
    </div> -->
    <Banner />
  </div>
</template>

<script>
import { getBrandingForCurrentLabel } from '@/utils/branding-helper'
import LanguageSelect from '@/components/LanguageSelect/LanguageSelect'
import { labelId } from '@/config'
import { mapActions } from 'vuex'
import Banner from '@/pages/Auth/Banner/Banner'

export default {
  name: 'Auth',
  components: { LanguageSelect, Banner },
  data: () => ({
    logoImg: '',
    brand: labelId ? labelId.toLowerCase() : ''
  }),
  computed: {
    currentRoute () {
      return this.$route.fullPath.substr(1)
    }
  },
  mounted () {
    this.finishAuthProgress() // make sure that setInProgress state is set in false initially
    getBrandingForCurrentLabel().then(
      (style) => (this.logoImg = require(`../../assets/label/${style.logo}`))
    )
  },
  methods: {
    ...mapActions('auth', ['finishAuthProgress'])
  }
}
</script>

<style src="./Auth.scss" lang="scss" scoped></style>

<style lang="scss">
.shuttel,
.greennl,
.porsche,
.dutchlease,
.enie,
.easyopweg,
.e-energie,
.coolblue,
.brandstof,
.mobilitics {
  .lang-select {
    a:not([role='menuitem']) {
      transition: text-shadow 0.3s linear;
      color: var(--label-color-text-secondary);

      &:hover {
        text-shadow: 2px 2px 3px black;
        color: var(--label-color-text-secondary);
      }
    }
  }
}

.ponenergie,
.e-pouwer {
  .lang-select {
    a:not([role='menuitem']) {
      transition: text-shadow 0.3s linear;
      color: var(--label-color-text-primary);

      &:hover {
        $shadow-color: transparentize(black, 0.5);
        text-shadow: 1px 1px 3px $shadow-color;
        color: var(--label-color-text-primary);
      }
    }
  }
}
</style>
