<template>
  <router-view />
</template>

<script>
import {
  loadFontCssForCurrentLabel,
  loadFaviconForCurrentLabel,
  getBrandingForCurrentLabel,
  getNameForCurrentLabel, setTitle
} from '@/utils/branding-helper'

export default {
  name: 'App',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  watch: {
    locale () {
      this.setTitleForBrand()
    }
  },
  created () {
    this.loadSavedLanguage()
  },
  mounted () {
    this.loadLabelStyling()
  },
  methods: {
    loadSavedLanguage () {
      const savedLanguage = window.localStorage.getItem('savedLanguage')
      if (savedLanguage) {
        this.$i18n.locale = savedLanguage
      } else {
        window.localStorage.setItem('savedLanguage', 'nl')
      }
    },
    loadLabelStyling () {
      getBrandingForCurrentLabel()
        .then(labelStyle => {
          const rootStyle = document.documentElement.style
          rootStyle.setProperty('--label-font', labelStyle.fontName)
          rootStyle.setProperty('--label-logo', labelStyle.logo)
          rootStyle.setProperty('--label-background', labelStyle.background)
          rootStyle.setProperty('--label-login-button-background', labelStyle.loginButtonBackground)
          rootStyle.setProperty('--label-login-button-background-hover', labelStyle.loginButtonBackgroundHover)
          rootStyle.setProperty('--label-login-button-background-image', labelStyle.loginButtonBackgroundImage)
          rootStyle.setProperty('--label-color-primary', labelStyle.colors.primary)
          rootStyle.setProperty('--label-color-primary-dark', labelStyle.colors.primaryDark)
          rootStyle.setProperty('--label-color-primary-light', labelStyle.colors.primaryLight)
          rootStyle.setProperty('--label-color-secondary', labelStyle.colors.secondary)
          rootStyle.setProperty('--label-color-secondary-dark', labelStyle.colors.secondaryDark)
          rootStyle.setProperty('--label-color-secondary-light', labelStyle.colors.secondaryLight)
          rootStyle.setProperty('--label-color-tertiary', labelStyle.colors.tertiary)
          rootStyle.setProperty('--label-color-tertiary-dark', labelStyle.colors.tertiaryDark)
          rootStyle.setProperty('--label-color-tertiary-light', labelStyle.colors.tertiaryLight)
          rootStyle.setProperty('--label-color-text-primary', labelStyle.colors.primaryText)
          rootStyle.setProperty('--label-color-text-secondary', labelStyle.colors.secondaryText)
          rootStyle.setProperty('--label-color-text-tertiary', labelStyle.colors.tertiaryText)
        })
      loadFontCssForCurrentLabel()
      loadFaviconForCurrentLabel()
      this.setTitleForBrand()
    },
    setTitleForBrand () {
      setTitle(`${this.$t(getNameForCurrentLabel())} ${this.$t('pages.common.portal')}`)
    }
  }
}
</script>

<style src="./styles/theme.scss" lang="scss"/>
