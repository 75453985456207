import Auth from '@aws-amplify/auth'
import store from '@/store'

const retry = ({ callback, number, wait }, condition, retries = 0, promise, resolve, reject) => {
  if (!promise) {
    promise = new Promise((resolve, reject) => {
      callback()
        .then(resolve)
        .catch(err => {
          if (!condition(err) || retries >= number) {
            reject(err)
          } else {
            setTimeout(() => {
              retry({ callback, number, wait }, condition, ++retries, promise, resolve, reject)
            }, wait)
          }
        })
    })

    return promise
  } else {
    callback(true)
      .then(resolve)
      .catch(err => {
        if (!condition(err) || retries >= number) reject(err)
        else {
          setTimeout(() => {
            retry({ callback, number, wait }, condition, ++retries, promise, resolve, reject)
          }, wait)
        }
      })
  }
}

export const refreshSessionAndCall = (callback) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(session => {
        store.commit('auth/setUserCredentials', session)
        callback(false)
          .then(resolve)
          .catch(reject)
      })
      .catch(err => {
        console.warn(err)
        return reject(err)
      })
  })
}

const bindRetryCondition = condition => ({ callback, number, wait }, retries = 0, promise, resolve, reject) => retry({ callback, number, wait }, condition, retries, promise, resolve, reject)

export const retryExpired = bindRetryCondition(error => error?.response?.data?.exp === 'token expired')
