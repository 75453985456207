<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center mx-1 mb-2">
      <h4 class="font-weight-normal">
        {{ title }}
      </h4>
      <export-to-excel-button v-if="leadsAccess" :loading="excelLoading" @click="export2Excel" />
    </b-row>
    <b-row>
      <b-col xs="12">
        <div class="pb-xlg h-100">
          <div class="table-responsive">
            <v-server-table
              ref="table"
              class="installations-table"
              :columns="columns"
              :options="options"
            />
            <div v-if="loading" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
              <b-spinner class="fetching-indicator" type="grow" label="Spinning" />
            </div>
            <b-button
              v-if="showNextPage"
              class="center load-more-button"
              @click="loadAdditionalData()"
            >
              {{ $t('pages.common.loadMore') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ExportToExcelButton from '@/components/ExportToExcelButton/ExportToExcelButton'

export default {
  name: 'InstallationsTable',
  components: {
    ExportToExcelButton
  },
  props: ['title'],
  data () {
    return {
      sortable: [
        'dealerLocation',
        'advisorName',
        'driverName',
        'contractNumber',
        'contractDate',
        'cpDeliveryDateCommunicated',
        'cpDeliveryDate',
        'cpOrdered',
        'cpInstallationDate'
      ],
      linesCount: 25,
      loading: false,
      excelLoading: false,
      showNextPage: false
    }
  },
  computed: {
    ...mapState('auth', ['relationId', 'userGroups']),
    ...mapGetters('filtering', ['getDateFilter']),
    leadsAccess () {
      return this.userGroups.includes('LEADS')
    },
    columns () {
      let availableColumns = []
      if (this.userGroups.includes('LEADS')) {
        availableColumns = [
          'dealerLocation',
          'advisorName',
          'driverName',
          'contractNumber',
          'contractDate',
          'cpDeliveryDateCommunicated',
          'cpDeliveryDate',
          'cpOrdered',
          'cpInstallationDate'
        ]
      } else {
        availableColumns = [
          'driverName',
          'contractNumber',
          'contractDate',
          'cpDeliveryDate',
          'cpInstallationDate'
        ]
      }
      return availableColumns
    },
    headings () {
      let headingObject = {}
      if (this.userGroups.includes('LEADS')) {
        headingObject = {
          dealerLocation: this.$t('installations.dealerLocation'),
          advisorName: this.$t('installations.advisorName'),
          driverName: this.$t('installations.driverName'),
          contractNumber: this.$t('installations.contractNumber'),
          contractDate: this.$t('installations.contractDate'),
          cpDeliveryDateCommunicated: this.$t('installations.cpDeliveryDateCommunicated'),
          cpDeliveryDate: this.$t('installations.cpDeliveryDate'),
          cpOrdered: this.$t('installations.cpOrdered'),
          cpInstallationDate: this.$t('installations.cpInstallationDate')
        }
      } else {
        headingObject = {
          driverName: this.$t('installations.driverName'),
          contractNumber: this.$t('installations.contractNumber'),
          contractDate: this.$t('installations.contractDate'),
          cpDeliveryDate: this.$t('installations.cpDeliveryDate'),
          cpInstallationDate: this.$t('installations.cpInstallationDate')
        }
      }
      return headingObject
    },
    options () {
      return {
        headings: this.headings,
        columnsDisplay: {
          advisorName: 'min_desktop',
          cpDeliveryDateCommunicated: 'min_desktop',
          cpDeliveryDate: 'min_desktop',
          cpOrdered: 'min_desktop',
          dealershipLocation: 'min_desktop'
        },
        sortable: this.sortable,
        cellClasses: {
          cost: [
            {
              class: 'column-align-right',
              condition: () => true
            }
          ] },
        orderBy: {
          column: 1,
          ascending: false
        },
        filterable: false,
        pagination: { chunk: 25, dropdown: false },
        perPageValues: [],
        perPage: 1000,
        // this parameter we need for hide dafault pagination panel
        texts: {
          limit: '',
          count: '',
          noResults: this.$t('pages.common.noResults'),
          loading: this.$t('pages.common.loading')
        },
        skin: 'table',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        },
        requestAdapter: data => {
          return {
            page: data.page - 1,
            size: this.linesCount,
            sortBy: this.getSortByForColumn(data.orderBy),
            sortingDir: data.ascending
          }
        },
        requestFunction: this.loadData
      }
    }
  },
  methods: {
    getSortByForColumn (column) {
      switch (column) {
        case 'dealerLocation':
          return 'dealer_location'
        case 'advisorName':
          return 'sales_advisor'
        case 'driverName':
          return 'quotes__contract__buyers__debtor__last_name'
        case 'contractNumber':
          return 'external_contract_number'
        case 'contractDate':
          return 'quotes__contract__start_date'
        case 'cpDeliveryDate':
          return 'charge_point_delivery_date'
        case 'cpDeliveryDateCommunicated':
          return 'charge_point_delivery_date_communicated'
        case 'cpOrdered':
          return 'charge_point_order_date'
        case 'cpInstallationDate':
          return 'charge_point_installation_date'
      }
    },
    loadData (data) {
      if (this.loading) {
        return Promise.resolve({
          count: 0,
          data: []
        })
      }
      this.loading = true
      return this.$installationsService.getInstallations({
        paging: data
      }).then(response => {
        this.showNextPage = response.hasNextPage
        response.count = response.data.length || 0
        if (response.count) {
          this.$emit('show')
        }
        // res.count should mutate here for avoid showing default pagination panel
        return response
        // eslint-disable-next-line no-console
      }).catch(err => {
        console.error(err)
        return {
          count: 0,
          data: []
        }
      })
        .finally(() => (this.loading = false))
    },
    loadAdditionalData () {
      this.linesCount += 25
      this.$refs.table.refresh()
    },
    export2Excel () {
      const filters = {}
      if (this.getDateFilter.startDate) {
        filters['quotes_contract_start_date__gte'] = this.getDateFilter.startDate.split('T')[0]
      }
      if (this.getDateFilter.stopDate) {
        filters['quotes_contract_start_date__lte'] = this.getDateFilter.stopDate.split('T')[0]
      }
      const fields = [
        'dealer_location',
        'sales_advisor',
        'quotes.first.driver_name',
        'external_contract_number',
        'quotes.first.contract.start_date',
        'charge_point_delivery_date_communicated',
        'charge_point_delivery_date',
        'charge_point_order_date',
        'charge_point_installation_date'
      ]
      const options = {
        model: 'opportunity',
        fileName: `${this.title}.xlsx`,
        fields: fields,
        headings: Object.values(this.headings),
        filters
      }
      this.excelLoading = true
      this.$exportService.exportToExcel(options)
        .finally(() => (this.excelLoading = false))
    }
  }
}
</script>
<style lang="scss">
.installations-table {
  th {
    white-space: nowrap !important;
    .VueTables__sort-icon {
      float: none;
      margin-left: 15px;
    }
  }
}
</style>
