<template>
  <form class="w-100 display-flex flex-column align-items-center" @submit.prevent="login">
    <div class="form-group w-100">
      <label class="label" for="username">{{ $t('pages.auth.username') }}</label>
      <input
        id="username"
        ref="username"
        class="form-control no-border"
        required
        type="text"
        name="username"
        autocomplete="off"
      >
    </div>
    <div class="form-group w-100">
      <label class="label" for="password">{{ $t('pages.auth.password') }}</label>
      <input
        id="password"
        ref="password"
        class="form-control no-border"
        required
        type="password"
        name="password"
      >
    </div>
    <b-button
      type="submit"
      size="sm"
      variant="inverse"
      :disabled="inProgress"
    >
      <span>{{ $t('pages.auth.login.submit') }}</span>
      <b-spinner v-if="inProgress" small type="grow" />
      <i v-else class="fa fa-user" />
    </b-button>
    <div class="links-section">
      <span v-show="!fromMultiforce" class="label link" @click="goToForgotPassword">
        {{ $t('pages.auth.login.forgotPassword') }}
      </span>
    </div>
  </form>
</template>

<script>
import router from '@/Routes'
import { createNamespacedHelpers } from 'vuex'
import { clearActivity } from '@/utils/activity-helper'
import { isMultiforceReferrer } from '@/utils/auth-helper'

import { getMessageForKey } from '@/i18n'

const { mapActions, mapState } = createNamespacedHelpers('auth')

export default {
  data: () => ({
    fromMultiforce: false
  }),
  computed: {
    ...mapState(['inProgress'])
  },
  mounted () {
    if (isMultiforceReferrer(document.referrer)) {
      this.fromMultiforce = true
      this.$refs.username.value = 'impersonator_'
    }
  },
  methods: {
    ...mapActions(['logIn', 'logOut']),
    login () {
      const username = this.$refs.username.value.toLowerCase()
      const password = this.$refs.password.value
      this.logIn({ username, password })
        .then(result => {
          if (result && result.flag === 'isImpersonator' && !this.fromMultiforce) {
            this.logOut().then(() => {
              this.$messageService.showErrorMessage({
                message: getMessageForKey('pages.auth.errors.wrongReferrer')
              })
            })
            return true
          } else if (result && result.flag === 'newPasswordRequired') {
            router.push('/complete-password')
            return true
          } else if (result && result.flag === 'unauthorized') {
            router.push('/unauthorized')
            return true
          }
          this.$messageService.hideAllMessages()
          clearActivity()
          this.$gtag.event('login', { method: 'Google' })
          router.push('/')
        })
        .catch(err => console.error(err))
    },
    goToForgotPassword () {
      this.$gtag.event('goToforgotPassword', { method: 'Google' })
      this.$messageService.hideAllMessages()
      router.push('forgot-password')
    }
  }
}
</script>

<style scoped>

</style>

<style src="../Auth.scss" lang="scss" scoped/>
