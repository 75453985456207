<template>
  <date-picker
    v-model="range"
    mode="range"
    class="date-picker"
    :locale="datePickerLocale"
    :masks="{input: ['DD-MM-YYYY']}"
    @input="dateSelected"
  >
    <div slot-scope="props">
      <i class="fa fa-calendar" />
      <input
        :placeholder="$t('mobility.dateRange')"
        :value="props.inputValue"
        class="date-picker-input"
        v-on="props.inputEvents"
      >
    </div>
  </date-picker>
</template>

<script>
import Vue from 'vue'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { mapMutations } from 'vuex'
const moment = require('moment')

Vue.component('Calendar', Calendar)
Vue.component('DatePicker', DatePicker)

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      range: {
        start: '',
        end: ''
      },
      triggeredInitialy: false,
      datePickerLocale: 'nl'
    }
  },
  mounted () {
    const savedLanguage = window.localStorage.getItem('savedLanguage')
    if (savedLanguage) {
      this.datePickerLocale = savedLanguage
    }

    this.initialStart = moment().startOf('month').subtract(6, 'months').utc()
    this.initialEnd = moment().utc()
    this.range = {
      start: this.initialStart.toDate(),
      end: this.initialEnd.toDate()
    }
    this.setInitialDates({
      start: this.initialStart.toISOString(false) + 'Z',
      end: this.initialEnd.toISOString(false) + 'Z'
    })
  },
  methods: {
    ...mapMutations('filtering', [
      'addDateFilter',
      'removeDateFilter',
      'setInitialDates'
    ]),
    dateSelected (newDate) {
      if (newDate) {
        this.addDateFilter({
          start: moment(newDate.start).toISOString(true) + 'Z',
          end: moment(newDate.end).toISOString(true) + 'Z'
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/app';

.date-picker {
  position: relative;

  i {
    color: var(--filters-icon-color);
    position: absolute;
    top: 11px;
    left: 15px;
  }

  &-input {
    line-height: 1;
    padding: 5.5px 5px 5.5px 35px;
    border-radius: 18px;
    border: 1px solid var(--filters-border-color);
    height: 36px;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 220px;
    }
  }
}
</style>
