<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center mx-1 mb-2">
      <h4 class="font-weight-normal">
        {{ title }}
      </h4>
      <div class="right-block">
        <input
          type="text"
          :placeholder="$t('lastName')"
          class="rounded-input"
          @input="filterByName"
        >
        <input
          type="text"
          :placeholder="$t('cardLeads.email')"
          class="rounded-input"
          @input="filterByEmail"
        >
        <export-to-excel-button :loading="excelLoading" class="export-button" @click="export2Excel" />
      </div>
    </b-row>
    <b-row>
      <b-col xs="12">
        <div class="pb-xlg h-100">
          <div class="table-responsive">
            <v-server-table
              ref="table"
              class="table"
              :columns="columns"
              :options="options"
            >
              <template slot="status" slot-scope="props">
                {{ getStatusTemplate(props.row.status) }}
              </template>
            </v-server-table>
            <div v-if="loading" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
              <b-spinner class="fetching-indicator" type="grow" label="Spinning" />
            </div>
            <b-button
              v-if="showNextPage"
              class="center load-more-button"
              @click="loadAdditionalData()"
            >
              {{ $t('pages.common.loadMore') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ExportToExcelButton from '@/components/ExportToExcelButton/ExportToExcelButton'
import _ from 'lodash-es'
import Vue from 'vue'

export default {
  name: 'CardLeadsTable',
  components: {
    ExportToExcelButton
  },
  props: ['title'],
  data () {
    return {
      columns: [
        'contractName',
        'formReceivedAt',
        'status',
        'cardNumber',
        'name',
        'companyName',
        'phoneNumber',
        'email'
      ],
      headings: {
        contractName: this.$t('leads.contractNumber'),
        formReceivedAt: this.$t('cardLeads.requestDate'),
        status: this.$t('leads.status'),
        cardNumber: this.$t('cardLeads.registrationNumber'),
        name: this.$t('cardLeads.name'),
        companyName: this.$t('cardLeads.company'),
        phoneNumber: this.$t('cardLeads.phoneNumber'),
        email: this.$t('cardLeads.email')
      },
      sortable: [
        'formReceivedAt',
        'companyName',
        'email'
      ],
      nameFilter: '',
      emailFilter: '',
      linesCount: 25,
      loading: false,
      excelLoading: false,
      showNextPage: false
    }
  },
  computed: {
    ...mapState('auth', ['relationId']),
    ...mapState('filtering', ['dateFilter']),
    ...mapGetters('filtering', ['getDateFilter']),
    options () {
      return {
        headings: this.headings,
        columnsDisplay: {
          formReceivedAt: 'min_desktop',
          name: 'min_desktop',
          companyName: 'min_desktop',
          phoneNumber: 'min_desktop',
          email: 'min_desktop'
        },
        sortable: this.sortable,
        cellClasses: {
          formReceivedAt: [{ class: 'date-column', condition: () => true }]
        },
        orderBy: {
          column: 'formReceivedAt',
          ascending: false
        },
        filterable: false,
        pagination: { chunk: 25, dropdown: false },
        perPageValues: [],
        perPage: 1000,
        // this parameter we need for hide dafault pagination panel
        texts: {
          limit: '',
          count: '',
          noResults: this.$t('pages.common.noResults'),
          loading: this.$t('pages.common.loading')
        },
        skin: 'table',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        },
        requestAdapter: data => {
          return {
            page: data.page - 1,
            size: this.linesCount,
            sortBy: this.getSortByForColumn(data.orderBy),
            sortingDir: data.ascending
          }
        },
        requestFunction: this.loadData
      }
    }
  },
  watch: {
    dateFilter: {
      immediate: true,
      handler () {
        Vue.nextTick().then(() => {
          this.$refs['table'] && this.$refs['table'].refresh()
        })
      }
    }
  },
  created () {
    this.debouncedRefresh = _.debounce((e) => {
      // we cant assign debounce as a method, it wont work in case of multiply components in use
      this.$refs.table.refresh()
    }, 300)
  },
  methods: {
    getSortByForColumn (column) {
      switch (column) {
        case 'formReceivedAt':
          return 'form_received_at'
        case 'companyName':
          return 'company_name'
        case 'email':
          return 'email'
      }
    },
    getStatusTemplate (initialStatus) {
      switch (initialStatus) {
        case 'requested':
          return this.$t('cardLeads.statuses.requested')
        case 'sent to customer':
          return this.$t('cardLeads.statuses.sent')
        case 'activated':
          return this.$t('cardLeads.statuses.activated')
      }
    },
    filterByName (event) {
      this.nameFilter = event.target.value
      this.debouncedRefresh()
    },
    filterByEmail (event) {
      this.emailFilter = event.target.value
      this.debouncedRefresh()
    },
    loadData (data) {
      if (this.loading || (!this.getDateFilter.startDate && !this.getDateFilter.stopDate)) {
        return Promise.resolve({
          count: 0,
          data: []
        })
      }
      this.loading = true
      return this.$cardLeadsService.getCardLeads({
        ...this.getDateFilter,
        lastName: this.nameFilter,
        email: this.emailFilter,
        paging: data
      }).then(response => {
        this.showNextPage = response.hasNextPage
        response.count = response.data.length || 0
        if (response.count) {
          this.$emit('show')
        }
        // res.count should mutate here for avoid showing default pagination panel
        return response
        // eslint-disable-next-line no-console
      }).catch(err => {
        console.error(err)
        return {
          count: 0,
          data: []
        }
      })
        .finally(() => (this.loading = false))
    },
    loadAdditionalData () {
      this.linesCount += 25
      this.$refs.table.refresh()
    },
    export2Excel () {
      const filters = {}
      if (this.getDateFilter.startDate) {
        filters['form_received_at__gte'] = this.getDateFilter.startDate.split('T')[0]
      }
      if (this.getDateFilter.stopDate) {
        filters['form_received_at__lte'] = this.getDateFilter.stopDate.split('T')[0]
      }
      filters['last_name__icontains'] = this.nameFilter
      filters['email__icontains'] = this.emailFilter
      const options = {
        model: 'usercardregistration',
        fileName: `${this.title}.xlsx`,
        fields: [
          'contract.name',
          'form_received_at',
          'leadview_status',
          'card.visible_number',
          'leadview_name',
          'company_name',
          'phone_number',
          'email'
        ],
        filters,
        headings: Object.values(this.headings)
      }
      this.excelLoading = true
      this.$exportService.exportToExcel(options)
        .finally(() => (this.excelLoading = false))
    }
  }
}
</script>

<style lang="scss">
.table {
  th {
    white-space: nowrap !important;
    .VueTables__sort-icon {
      float: none;
      margin-left: 15px;
    }
  }
}
</style>
<style scoped lang="scss">
.right-block {
  display: flex;

  .export-button {
    margin-left: 15px;
    height: max-content;
  }
  a {
    margin-left: 15px;
  }
}
a:hover {
  text-decoration: unset;
}
.dropdown-filter {
  border: var(--navbar-border);
  padding: 0.3rem;
  cursor: pointer;
  margin-left: 5px;
}
</style>
