<template>
  <div>
    <!-- GChart component is required to be wrapped inside a div -->
    <GChart
      v-if="chartData"
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
      :resize-debounce="500"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { labelId } from '@/config'
const moment = require('moment')

export default {
  name: 'ChargingSessionsChart',
  components: { GChart },
  props: {
    dates: {
      type: Array
    }
  },
  data: () => ({
    // Array will be automatically processed with visualization.arrayToDataTable function
    chartData: [
      ['Date', 'Count'],
      [new Date(), 0]
    ],
    chartOptions: {
      legend: 'none',
      hAxis: {
        format: 'MMM',
        gridlines: {
          color: 'transparent'
        }
      },
      vAxis: {
        gridlines: {
          color: 'transparent'
        }
      },
      animation: {
        startup: true,
        duration: 500,
        easing: 'ease-in-out'
      },
      // Fallback, color is set by primary label color
      colors: ['#D6041C']
    }
  }),
  watch: {
    dates: {
      immediate: true,
      handler (summary) {
        if (summary && summary.length) {
          this.chartData = [['Date', 'Count']]
          let tooltipFormat
          if (summary[0]['day']) {
            this.chartOptions.hAxis.format = 'dd/MM'
            tooltipFormat = 'dd/LL/yyyy'
          } else {
            this.chartOptions.hAxis.format = 'MMM'
            tooltipFormat = 'MMM'
          }
          this.chartOptions.hAxis.ticks = []
          summary.forEach((el) => {
            const date = el?.day || el?.month
            this.chartOptions.hAxis.ticks.push(new Date(date))
            this.chartData.push([
              {
                v: new Date(date),
                f: moment(date).format(tooltipFormat)
              },
              el.count
            ])
          })
        } else {
          this.chartData = [
            ['Date', 'Count'],
            [new Date(), 0]
          ]
        }
      }
    }
  },
  mounted () {
    this.loadLabelStyleColors()
  },
  methods: {
    async loadLabelStyleColors () {
      if (labelId) {
        const currentLabelStyle = await import(
          '@/label/' + labelId.toLowerCase() + '.js'
        )
        this.chartOptions.colors = [currentLabelStyle.default.colors.primary]
      }
    }
  }
}
</script>
