<template>
  <section>
    <b-container fluid class="container-widget">
      <b-row class="d-flex justify-content-between mobile-container-row">
        <b-col cols="auto" md="4">
          <h1 class="widget-title">
            {{ $t('pages.emobility.title') }}
          </h1>
        </b-col>
        <b-col cols="12" md="8" class="d-md-block">
          <b-row class="d-flex align-items-center filters-panel">
            <b-col cols="12" md="auto" class="filters-panel-item pr-1">
              <date-range-picker />
            </b-col>
            <b-col cols="12" md="auto" class="pl-1">
              <tokens-multiselect />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mobile-container-row">
        <b-col
          cols="12"
          md="9"
          lg="6"
          xl="4"
          class="pt-3 pb-3"
        >
          <SegmentedControls
            :parent-view="view"
            @interface="changeWidget"
          />
        </b-col>
      </b-row>
      <b-row class="mobile-container-row">
        <b-col cols="12" lg="6" xl="5">
          <transition mode="out-in">
            <component
              :is="widget"
              :metrics="cdrMetrics"
            />
          </transition>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          xl="7"
          class="mobile-container-col"
        >
          <transition mode="out-in">
            <component :is="chart" :dates="transactionTotals" />
          </transition>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="container-tabs">
      <b-row class="container-tabs-row">
        <b-col class="container-tabs-col">
          <b-tabs v-model="tabIndex">
            <b-tab
              v-if="leadsAccess"
              :title-item-class="{hidden: !showLeads}"
              :title="$t('leads.title')"
              href="#leads"
              :active="showLeads"
            >
              <LeadsTable :title="$t('leads.title')" @show="showLeads = true" />
            </b-tab>
            <b-tab
              :title-item-class="{hidden: !showInstallations}"
              :title="$t('installations.title')"
              href="#installations"
              :active="!showLeads && showInstallations"
            >
              <InstallationsTable :title="$t('installations.title')" @show="showInstallations = true" />
            </b-tab>
            <b-tab
              v-if="leadsAccess"
              :active="!showLeads && !showInstallations && showCardLeads"
              :title-item-class="{hidden: !showCardLeads}"
              :title="$t('cardLeads.title')"
              href="#card-leads"
            >
              <CardLeadsTable :title="$t('cardLeads.title')" @show="showCardLeads = true" />
            </b-tab>
            <b-tab :title="$t('mobility.chargingCdrs')" href="#cdrs" :active="!showLeads && !showInstallations && !showCardLeads">
              <TransactionsTable :title="$t('mobility.chargingCdrs')" />
            </b-tab>
            <b-tab :title="$t('mobility.chargingCards')" href="#cards">
              <ChargingTokensTable />
            </b-tab>
            <b-tab :title="$t('mobility.chargingSessions')" href="#sessions">
              <SessionsTable :title="$t('mobility.chargingSessions')" />
            </b-tab>
            <b-tab :title="$t('mobility.chargePoints')" href="#chargepoints">
              <ChargePointsTable />
            </b-tab>
            <b-tab :title="$t('mobility.invoices')" href="#invoices">
              <InvoicesTable />
            </b-tab>
            <b-tab v-if="labelId === 'shuttel'" :title="$t('sideOperations.title')" href="#costs">
              <SideOperationsTable :title="$t('sideOperations.title')" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <back-to-top visible-offset="600" />
    </b-container>
  </section>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import { mapGetters, mapState, mapActions } from 'vuex'
import { labelId } from '@/config'

import SegmentedControls from '@/components/SegmentedControls/SegmentedControls'
import ChargingSessionsWidget from '@/components/Widgets/ChargingSessionsWidget'
import ChargingSessionsChart from '@/components/Charts/ChargingSessionsChart'
import ChargingCostsWidget from '@/components/Widgets/ChargingCostsWidget'
import ChargingCostsChart from '@/components/Charts/ChargingCostsChart'
import ConsumptionWidget from '@/components/Widgets/ConsumptionWidget'
import ConsumptionChart from '@/components/Charts/ConsumptionChart'
import TransactionsTable from '@/components/TransactionsTable/TransactionsTable'
import ChargingTokensTable from '@/components/ChargingTokensTable/ChargingTokensTable'
import ChargePointsTable from '@/components/ChargePointsTable/ChargePointsTable'
import InvoicesTable from '@/components/InvoicesTable/InvoicesTable'
import TokensMultiselect from '@/components/TokensMultiselect/TokensMultiselect'
import DateRangePicker from '@/components/DateRangePicker/DateRangePicker'
import SessionsTable from '@/components/SessionsTable'
import SideOperationsTable from '@/components/SideOperationsTable'
import LeadsTable from '@/components/LeadsTable'
import CardLeadsTable from '@/components/CardLeadsTable'
import InstallationsTable from '@/components/InstallationsTable'
import BackToTop from '@/components/BackToTop'
import moment from 'moment'
import router, { loginPath } from '@/Routes'

export default {
  name: 'Mobility',
  components: {
    SegmentedControls,
    ChargingSessionsWidget,
    ChargingSessionsChart,
    ChargingCostsWidget,
    ChargingCostsChart,
    ConsumptionWidget,
    ConsumptionChart,
    TransactionsTable,
    ChargingTokensTable,
    ChargePointsTable,
    InvoicesTable,
    SideOperationsTable,
    LeadsTable,
    CardLeadsTable,
    InstallationsTable,
    TokensMultiselect,
    DateRangePicker,
    SessionsTable,
    BackToTop
  },
  data: () => ({
    view: 'chargingsessions',
    widget: 'ChargingSessionsWidget',
    chart: 'ChargingSessionsChart',
    tabIndex: 0,
    tabs: ['#leads', '#cardLeads', '#cdrs', '#sessions', '#cards', '#chargepoints', '#invoices'],
    labelStyleColors: 0,
    dataLoading: false,
    transactionTotals: [],
    labelId: null,
    showInstallations: false,
    showLeads: false,
    showCardLeads: false,
    cdrMetrics: {
      fast: {
        count: 0,
        energy: 0
      },
      slow: {
        count: 0,
        energy: 0
      },
      cardsInUse: 0
    }
  }),
  computed: {
    ...mapState('auth', ['relationId', 'userGroups']),
    ...mapGetters('auth', ['token', 'accessToken', 'refreshToken']),
    ...mapState('filtering', ['dateFilter', 'tokenFilters']),
    ...mapGetters('filtering', ['getDateFilter']),
    defaultDateFilter () {
      const dateWithPreviousMonth = moment().subtract(1, 'months').date(1)
      return { year: dateWithPreviousMonth.year(), month: dateWithPreviousMonth.month() }
    },
    leadsAccess () {
      return this.userGroups.includes('LEADS')
    }
  },
  watch: {
    dateFilter () {
      this.loadSummary()
    },
    tokenFilters () {
      this.loadSummary()
    }
  },
  mounted () {
    this.loadLabelStyleColors()
    this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash)
    this.labelId = labelId
  },
  created () {
    if (!this.accessToken || this.$route.query['impersonator']) {
      // EMO-2657 - no second session for impersonator, if there are stored session data
      // and somebody came with query param impersonator - log out
      this.logOut().finally(() => {
        router.push(loginPath)
      })
      return
    }
    const ts = Math.round((new Date()).getTime() / 1000)
    // Check if timestamp is before expire date by Unix Epoch
    if (this.accessToken.payload.exp > ts) {
      // Refresh auth token by refreshToken
      this.refreshAuthSession()
    }
    Vue.$invoiceService.init({ token: this.token })
    Vue.$settingsService.init({ token: this.token })
    Vue.$tokenService.init({ token: this.token })
    Vue.$transactionService.init({ token: this.token })
    Vue.$chargePointService.init({ token: this.token })
    Vue.$exportService.init(this.token)
    Vue.$sideOperationService.init(this.token)
    Vue.$leadsService.init(this.token)
    Vue.$cardLeadsService.init(this.token)
    Vue.$installationsService.init(this.token)
    store.dispatch('tokens/loadTokens', { root: true })
  },
  methods: {
    ...mapActions('auth', ['refreshAuthSession', 'logOut']),
    updateProp () {
      this.normalChargingCount++
    },
    changeWidget (event) {
      if (event === 'chargingsessions') {
        this.view = 'chargingsessions'
        this.widget = 'ChargingSessionsWidget'
        this.chart = 'ChargingSessionsChart'
      } else if (event === 'consumption') {
        this.view = 'consumption'
        this.widget = 'ConsumptionWidget'
        this.chart = 'ConsumptionChart'
      } else {
        this.view = 'chargingcosts'
        this.widget = 'ChargingCostsWidget'
        this.chart = 'ChargingCostsChart'
      }
    },
    loadSummary () {
      // its kind of strange to retrieve this information again in the same time as transactions table,
      // but its done by this way because backed provide paginated list and summary in same endpoint
      // and we can't mutate summary data from the table component which should be independent
      if (this.dataLoading) {
        return
      }
      this.dataLoading = true
      const tokens = this.tokenFilters.map(el => el.chargingTokenNr)
      this.$transactionService.getTransactions({ ...this.getDateFilter, tokens })
        .then(response => {
          this.dataLoading = false
          this.transactionTotals = response?.totals || []
          if (response?.metrics) {
            this.cdrMetrics = response?.metrics
          }
        })
    },
    clearMetrics () {
      this.cdrMetrics = {
        fast: {
          count: 0,
          energy: 0
        },
        slow: {
          count: 0,
          energy: 0
        },
        cardsInUse: 0
      }
    },
    async loadLabelStyleColors () {
      if (labelId) {
        const currentLabelStyle = await import('@/label/' + labelId.toLowerCase() + '.js')
        this.labelStyleColors = currentLabelStyle.default.colors
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/app';

.container-widget {
  padding: 30px;
  background: $widget-bg;
  border-bottom: var(--content-border);

  @include media-breakpoint-down(sm) {
    padding: 10px 0;
  }

  .widget-title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0;
  }

  .mobile-container-row {
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .mobile-container-col {
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.container-tabs {
  padding: 0;
  background-color: var(--tabs-background-color);
  background-image: var(--tabs-background-image);

  &-row {
    padding: 0 10px;

    @include media-breakpoint-down(sm) {
      margin: 0;
      padding: 0;
    }

    &-toolbar {
      padding: 20px 20px 0px 20px;
    }
  }

  &-col {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
}

.filters-panel {
  justify-content: flex-end;
  margin-top: 10px;

  @media (max-width: breakpoint-min(md)) {
    justify-content: space-around;
    margin-right: -20px;
    margin-left: -20px;

    &-item:first-of-type {
      margin-bottom: 10px;
    }

    .pr-1 {
      padding-right: 20px !important;
    }

    .pl-1 {
      padding-left: 20px !important;
    }

    .col-auto {
      padding: 0;
    }
  }
}
</style>
<style lang="scss">
.hidden {
  display: none;
}
</style>
