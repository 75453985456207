<template>
  <div class="animated-tile">
    <h3 v-if="value">
      {{ initValueTween | numFormat(valueFormat) }}
    </h3>
    <h3 v-else>
      {{ valueFormat || '0' }}
    </h3>
    <h6 v-if="title">
      {{ title }}
    </h6>
  </div>
</template>

<script>
import VueMixinTween from 'vue-mixin-tween'

export default {
  name: 'AnimatedTile',
  mixins: [
    // The only required argument is the name of the property to tween.
    // The default duration is 500 milliseconds.
    // The default timing function is TWEEN.Easing.Quadratic.Out
    // We're using a "custom" linear timing function here.
    VueMixinTween('initValue', 1000, (pos) => pos)
  ],
  props: {
    // The title of the tile
    title: {
      type: String
    },
    // The value that we'll be tweening to.
    value: {
      type: Number,
      default: 0
    },
    // The numeral formating e.g. '0.00', '0.0' or '0'
    valueFormat: {
      type: String,
      default: '0'
    },
    // How long the tween should take. (In milliseconds.)
    tweenDuration: {
      type: Number,
      default: 300
    }
  },
  // This holds the temporary state of the tweened value.
  data () {
    return {
      initValue: 0
    }
  },
  watch: {
    // Watch Props to accept async updates
    value () {
      this.tweenValue()
    }
  },
  mounted () {
    this.tweenValue()
  },
  methods: {
    tweenValue () {
      this.initValue = this.value
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/app';

.animated-tile {
  padding: 10px;

  @include media-breakpoint-up(md) {
    padding: 20px 0;
  }

  h3, h6 {
    color: black;
    line-height: 1;
  }

  h3 {
    font-size: 2.8rem;
    margin-bottom: 2px;
  }

  h6 {
    font-size: 1rem;
    margin-bottom: 0;
  }
}
</style>
