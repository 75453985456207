import { downloadFile } from '../../utils/file-helper'
import { AbstractService } from '../abstract'
import Vue from 'vue'
import { getMessageForKey } from '../../i18n'
import { i18n } from '@/i18n'

class ExportService extends AbstractService {
  exportToExcel ({ model, fileName, fields, headings, filters }) {
    return this.getData(({ model, fileName, fields, headings, filters }) => {
      return this.http.post('/export/', {
        format: 'xlsx',
        portal: location.hostname,
        locale: i18n.locale,
        request: [{
          model,
          fields,
          column_names: headings,
          filters
        }]
      }, {
        responseType: 'blob',
        timeout: 300000
      }
      ).then(resp => {
        if (resp.status === 200) {
          downloadFile(resp.data, fileName)
        } else if (resp.status === 202) {
          Vue.$messageService.showInfoMessage({
            message: getMessageForKey('pages.common.sentByEmail')
          })
        }
        return Promise.resolve()
      }).catch(err => {
        console.error(err)
        throw Promise.reject(err)
      })
    },
    { model, fileName, fields, headings, filters }
    )
  }
}

export const exportService = new ExportService({ baseURL: '/mobility2/secured' })
