<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center mx-1 mb-2">
      <h4 class="font-weight-normal">
        {{ title }}
      </h4>
      <export-to-excel-button :loading="excelLoading" @click="export2Excel" />
    </b-row>
    <b-row>
      <b-col xs="12">
        <div class="pb-xlg h-100">
          <div class="table-responsive">
            <v-server-table
              ref="table"
              :columns="columns"
              :options="options"
            />
            <div v-if="loading" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
              <b-spinner class="fetching-indicator" type="grow" label="Spinning" />
            </div>
            <b-button
              v-if="showNextPage"
              class="center load-more-button"
              @click="loadAdditionalData()"
            >
              {{ $t('pages.common.loadMore') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ExportToExcelButton from '@/components/ExportToExcelButton/ExportToExcelButton'

export default {
  name: 'SideOperationsTable',
  components: {
    ExportToExcelButton
  },
  props: ['title'],
  data () {
    return {
      columns: [
        'productName',
        'cardNumber',
        'start',
        'startLocation',
        'declarationText',
        'cost'
      ],
      headings: {
        productName: this.$t('sideOperations.type'),
        cardNumber: this.$t('sideOperations.cardNumber'),
        start: this.$t('sideOperations.startsOn'),
        startLocation: this.$t('sideOperations.startLocation'),
        declarationText: this.$t('sideOperations.declarationText'),
        cost: this.$t('sideOperations.rawCostsExVat')
      },
      sortable: [
        'productName',
        'cardNumber',
        'start',
        'startLocation',
        'declarationText',
        'cost'
      ],
      linesCount: 25,
      loading: false,
      excelLoading: false,
      showNextPage: false
    }
  },
  computed: {
    ...mapState('auth', ['relationId']),
    ...mapState('filtering', ['tokenFilters']),
    ...mapGetters('filtering', ['getDateFilter']),
    options () {
      return {
        headings: this.headings,
        columnsDisplay: {
          startLocation: 'min_desktop',
          declarationText: 'min_desktop'
        },
        sortable: this.sortable,
        cellClasses: {
          cost: [
            {
              class: 'column-align-right',
              condition: () => true
            }
          ] },
        orderBy: {
          column: 1,
          ascending: false
        },
        filterable: false,
        pagination: { chunk: 25, dropdown: false },
        perPageValues: [],
        perPage: 1000,
        // this parameter we need for hide dafault pagination panel
        texts: {
          limit: '',
          count: '',
          noResults: this.$t('pages.common.noResults'),
          loading: this.$t('pages.common.loading')
        },
        skin: 'table',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        },
        requestAdapter: data => {
          return {
            page: data.page - 1,
            size: this.linesCount,
            sortBy: this.getSortByForColumn(data.orderBy),
            sortingDir: data.ascending
          }
        },
        requestFunction: this.loadData
      }
    }
  },
  watch: {
    getDateFilter: {
      handler () {
        // eslint-disable-next-line no-console
        console.log()
        // without this console the request triggers twise
        if (this.$refs.table) {
          this.$refs.table.refresh()
        }
      }
    },
    tokenFilters: {
      handler () {
        if (this.$refs.table) {
          this.$refs.table.refresh()
        }
      }
    }
  },
  methods: {
    getSortByForColumn (column) {
      switch (column) {
        case 'productName':
          return 'product_name_or_type,'
        case 'cardNumber':
          return 'card__visible_number'
        case 'start':
          return 'start'
        case 'startLocation':
          return 'start_location'
        case 'declarationText':
          return 'ext_declaration_text'
        case 'cost':
          return 'netto_amount'
      }
    },
    loadData (data) {
      if (this.loading ||
        !(this.getDateFilter.startDate || this.getDateFilter.stopDate)
      ) {
        return Promise.resolve({
          count: 0,
          data: []
        })
      }
      this.loading = true
      const tokens = this.tokenFilters.map(el => el.chargingTokenNr)
      return this.$sideOperationService.getOperations({
        ...this.getDateFilter,
        tokens,
        paging: data
      }).then(response => {
        this.showNextPage = response.hasNextPage
        response.count = response.data.length || 0
        // res.count should mutate here for avoid showing default pagination panel
        return response
        // eslint-disable-next-line no-console
      }).catch(err => {
        console.error(err)
        return {
          count: 0,
          data: []
        }
      })
        .finally(() => (this.loading = false))
    },
    loadAdditionalData () {
      this.linesCount += 25
      this.$refs.table.refresh()
    },
    export2Excel () {
      const filters = {}
      if (this.getDateFilter.startDate) {
        filters['start__gte'] = this.getDateFilter.startDate.split('T')[0]
      }
      if (this.getDateFilter.stopDate) {
        filters['start__lte'] = this.getDateFilter.stopDate.split('T')[0]
      }
      const options = {
        model: 'publictransportandparkingtransaction',
        fileName: `${this.title}.xlsx`,
        fields: [
          'product_name_or_transport_type',
          'card.visible_number',
          'start',
          'start_location',
          'ext_declaration_text',
          'netto_amount'
        ],
        headings: Object.values(this.headings),
        filters
      }
      this.excelLoading = true
      this.$exportService.exportToExcel(options)
        .finally(() => (this.excelLoading = false))
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../styles/app';
  h4 {
    margin-bottom: 0;
  }
</style>
