const Labels = {
  GREENNL: 'greennl',
  COOLBLUE: 'coolblue',
  PONENERGIE: 'ponenergie',
  SHUTTEL: 'shuttel',
  PORSCHE: 'porsche',
  DUTCHLEASE: 'dutchlease',
  EASYOPWEG: 'easyopweg',
  TOBEGREEN: 'b-green',
  EENERGIE: 'e-energie',
  EPOUWER: 'e-pouwer',
  MOBILITICS: 'mobilitics',
  ENIE: 'enie',
  CUBE: 'cube',
  PONDEALER: 'pondealer',
  BRANDSTOF: 'brandstof',
  ALLURECHARGE: 'allurecharge',
  HOOGENBOOM: 'hoogenboom',
  LAADKRACHT: 'laadkracht',
  H2E: 'h2e',
  APOINT: 'a-point'
}
// value should be same as in label domain

Object.freeze(Labels)
export default Labels
