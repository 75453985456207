export default {
  token: null,
  accessToken: null,
  refreshToken: null,
  relationId: null,
  accountId: null,
  userGivenName: (process.env.VUE_APP_MODE === 'dev' && process.env.VUE_APP_USE_REAL_SERVICES === 'false') ? 'John' : null,
  inProgress: false,
  cognitoUser: null,
  isImpersonator: false,
  userGroups: []
}
