<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center mx-1 mb-2">
      <h4 class="font-weight-normal">
        {{ $t('pages.emobility.sections.chargingTokens.title') }}
      </h4>
      <export-to-excel-button :loading="excelLoading" @click="export2Excel" />
    </b-row>
    <b-row>
      <b-col xs="12">
        <div class="pb-xlg h-100">
          <div class="table-responsive">
            <v-server-table
              ref="table"
              :columns="columns"
              :options="options"
            >
              <template slot="isPromotion" slot-scope="props">
                {{ getIsPromotionTemplate(props.row.isPromotion) }}
              </template>
            </v-server-table>
            <div v-if="loading" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
              <b-spinner class="fetching-indicator" type="grow" label="Spinning" />
            </div>
            <b-button
              v-if="showNextPage"
              class="center load-more-button"
              @click="loadAdditionalData()"
            >
              {{ $t('pages.common.loadMore') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ExportToExcelButton from '@/components/ExportToExcelButton/ExportToExcelButton'
import StatusCell from '../StatusCell'
import { getLocaleStringForStatus } from '@/utils/i18n-helper'

export default {
  name: 'ChargingTokensTable',
  components: {
    ExportToExcelButton
  },
  data () {
    return {
      columns: [
        'chargingTokenNr',
        'type',
        'status',
        'leftAmount',
        'isPromotion'
      ],
      sortable: [
        'chargingTokenNr',
        'type'
      ],
      loading: false,
      excelLoading: false,
      items: [],
      chunk: 25,
      linesCount: 0,
      showNextPage: false
    }
  },
  computed: {
    options () {
      return {
        headings: {
          chargingTokenNr: this.$t('pages.emobility.sections.chargingTokens.columns.chargingTokenNr'),
          type: this.$t('pages.emobility.sections.chargingTokens.columns.type'),
          status: this.$t('pages.emobility.sections.chargingTokens.columns.status'),
          leftAmount: this.$t('pages.emobility.sections.chargingTokens.columns.leftAmount'),
          isPromotion: this.$t('pages.emobility.sections.chargingTokens.columns.isPromotion')
        },
        templates: {
          status: StatusCell
        },
        filterable: false,
        perPage: 1000,
        pagination: { chunk: 25, dropdown: false },
        perPageValues: [],
        texts: {
          limit: '',
          count: '',
          noResults: this.$t('pages.common.noResults')
        },
        skin: 'table',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        },
        requestAdapter: data => {
          return {
            page: data.page - 1,
            size: this.linesCount,
            sort: {
              sortBy: this.getSortByForColumn(data.orderBy),
              sortingDir: data.ascending
            }
          }
        },
        requestFunction: this.loadItems
      }
    }
  },
  created () {
    this.linesCount = this.chunk
  },
  methods: {
    loadItems (data) {
      const emptyResponse = {
        count: 0,
        data: []
      }
      if (this.loading) {
        return Promise.resolve(emptyResponse)
      }
      this.loading = true
      return this.$tokenService.getTokens({
        paging: data
      }).then(res => {
        this.items = res.data = res.data.map(el => {
          return {
            ...el,
            type: this.$t(this.getStringForType(el.type)),
            i18nStatus: this.$t(getLocaleStringForStatus(el.status))
          }
        })
        this.showNextPage = res.hasNextPage
        res.count = this.items.length || 0
        // res.count should mutate here for avoid showing default pagination panel
        return res
        // eslint-disable-next-line no-console
      }).catch(err => {
        console.error(err)
        return (emptyResponse)
      })
        .finally(() => (this.loading = false))
    },
    loadAdditionalData () {
      this.linesCount += this.chunk
      this.$refs.table.refresh()
    },
    export2Excel () {
      const options = {
        model: 'card',
        fileName: `${this.$t('pages.emobility.sections.chargingTokens.title')}.xlsx`,
        fields: [
          'visible_number',
          'type',
          'state',
          'promotional_credit.total_amount',
          'promotional_credit.used_amount',
          'promotional_credit.left_amount'
        ],
        headings: [
          this.$t('pages.emobility.sections.chargingTokens.columns.chargingTokenNr'),
          this.$t('pages.emobility.sections.chargingTokens.columns.type'),
          this.$t('pages.emobility.sections.chargingTokens.columns.status'),
          this.$t('pages.emobility.sections.chargingTokens.columns.totalAmount'),
          this.$t('pages.emobility.sections.chargingTokens.columns.usedAmount'),
          this.$t('pages.emobility.sections.chargingTokens.columns.leftAmount')
        ]
      }
      this.excelLoading = true
      this.$exportService.exportToExcel(options)
        .finally(() => (this.excelLoading = false))
    },
    getStringForType (type) {
      switch (type) {
        case 'CARD':
          return 'pages.emobility.sections.chargingTokens.types.chargingCard'
        case 'KEYFOB':
          return 'pages.emobility.sections.chargingTokens.types.keyChain'
        default:
          return ''
      }
    },
    getSortByForColumn (column) {
      switch (this.columns.indexOf(column)) {
        default:
        case 0:
          return 'visible_number'
        case 1:
          return 'type'
        case 2:
          return 'state'
      }
    },
    getIsPromotionTemplate (value) {
      return value ? this.$t('yes') : this.$t('no')
    }
  }
}
</script>

<style scoped lang="scss">
h4 {
  margin-bottom: 0;
}
</style>
