export default {
  setName: (state, name) => {
    state.name = name
  },
  setUserEmail: (state, userEmail) => {
    state.userEmail = userEmail
  },
  setAccountEmail: (state, accountEmail) => {
    state.accountEmail = accountEmail
  }
}
