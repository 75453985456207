import Vue from 'vue'

export const messageService = {
  messages: [],

  showMessage ({ type, message, dismissable = true }) {
    const action = !dismissable ? null : {
      text: 'X',
      onClick: (e, toastObj) => toastObj.goAway(0)
    }
    return Vue.toasted.show(message, {
      type,
      action,
      theme: 'outline',
      position: 'bottom-center',
      closeOnSwipe: dismissable
    })
  },

  showErrorMessage ({ message, dismissable }) {
    const toast = this.showMessage({ type: 'error', message, dismissable })
    this.messages.push(toast)
    return toast
  },

  showInfoMessage ({ message, dismissable }) {
    const toast = this.showMessage({ type: 'info', message, dismissable })
    this.messages.push(toast)
    return toast
  },

  hideAllMessages () {
    this.messages.forEach(toast => Vue.toasted.remove(toast.el))
    this.messages = []
  }
}

if (process.env.VUE_APP_MODE === 'dev') {
  window.messages = messageService
}
