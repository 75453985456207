import Vue from 'vue'
import { getMessageForKey } from '@/i18n'

export default {
  async loadChargePoints ({ commit }) {
    try {
      const items = await Vue.$chargePointService.getChargePoints()
      commit('saveChargePoints', items)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      Vue.$messageService.showErrorMessage({
        message: getMessageForKey('pages.emobility.errors.loading.chargePoints')
      })
    }
  }
}
