let maxInactivity = (60 * 25)
let maxRetries = 3
let hidden = null
let visibilityChange = null

export function activityWatcher () {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  // Warn if the browser doesn't support addEventListener or the Page Visibility API
  if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
    // eslint-disable-next-line
    console.error("This requires a more modern browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
  } else {
    // Handle page visibility change
    document.addEventListener(visibilityChange, () => { handleVisibilityChange() })
  }

  document.addEventListener('mousedown', () => { activity(true) })
  document.addEventListener('mousemove', () => { activity(true) })
  document.addEventListener('keydown', () => { activity(true) })
  document.addEventListener('scroll', () => { activity(true) })
  document.addEventListener('touchstart', () => { activity(true) })

  setInterval(() => {
    activity(false)
  }, 1000)
}

export function removeActivityWatcher () {
  document.removeEventListener(visibilityChange, () => { handleVisibilityChange() }, false)
  document.removeEventListener('mousedown', () => { activity(true) }, false)
  document.removeEventListener('mousemove', () => { activity(true) }, false)
  document.removeEventListener('keydown', () => { activity(true) }, false)
  document.removeEventListener('scroll', () => { activity(true) }, false)
  document.removeEventListener('touchstart', () => { activity(true) }, false)
}

export function handleVisibilityChange () {
  if (!document[hidden]) {
    activity(false)
  }
}

export function activity (skipSetActivity = false) {
  let lastActivity = 0
  if (localStorage.getItem('lastActivity')) {
    lastActivity = Number(localStorage.getItem('lastActivity'))
  }
  const currentActivity = Math.round((new Date()).getTime() / 1000)

  if ((currentActivity - lastActivity) > maxInactivity) {
    activityRefresh()
    localStorage.setItem('lastActivity', Math.round((new Date()).getTime() / 1000))
  }

  if (skipSetActivity === true) {
    localStorage.setItem('lastActivity', Math.round((new Date()).getTime() / 1000))
  }
}

export function activityRefresh () {
  let retriesActivity = 0
  if (localStorage.getItem('retriesActivity')) {
    retriesActivity = Number(localStorage.getItem('retriesActivity'))
  }

  if (retriesActivity >= maxRetries) {
    // Clear all items amd force user logout and show message
    localStorage.clear()
    sessionStorage.clear()
    location.reload()
  } else {
    localStorage.setItem('retriesActivity', (retriesActivity + 1))
    localStorage.removeItem('lastActivity')
    // Refresh the page to force complete new session
    location.reload()
  }
}

export function clearActivity () {
  localStorage.removeItem('lastActivity')
  localStorage.removeItem('retriesActivity')
}
