export const getLocaleStringForStatus = (status) => {
  switch (status) {
    case 'INACTIVE':
      return 'pages.emobility.sections.chargingTokens.statuses.inactive'
    case 'ALLOCATED':
      return 'pages.emobility.sections.chargingTokens.statuses.allocated'
    case 'DELIVERED':
      return 'pages.emobility.sections.chargingTokens.statuses.delivered'
    case 'ACTIVE':
      return 'pages.emobility.sections.chargingTokens.statuses.active'
    case 'ORDERED':
      return 'pages.emobility.sections.chargingTokens.statuses.ordered'
    case 'BLOCKED':
      return 'pages.emobility.sections.chargingTokens.statuses.blocked'
    case 'IN_STOCK':
      return 'pages.emobility.sections.chargingTokens.statuses.in_stock'
    default:
      return ''
  }
}
