<template>
  <b-nav-item-dropdown
    class="mr-2"
    extra-menu-classes="py-0"
    right
    no-caret
  >
    <template slot="button-content">
      <span>{{ $t(`languages.${$i18n.locale}`) }}</span>
    </template>
    <b-dropdown-item v-for="lang in getLanguages" :key="lang.key" @click="changeLanguage(lang.key)">
      {{ lang.value }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  name: 'LanguageSelect',
  computed: {
    getLanguages () {
      return Object.entries(this.$t('languages')).map(([key, value]) => ({ key, value }))
    }
  },
  methods: {
    changeLanguage (languageKey) {
      this.$i18n.locale = languageKey
      window.localStorage.setItem('savedLanguage', languageKey)
    }
  }
}
</script>
