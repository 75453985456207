<template>
  <form class="w-100 display-flex flex-column align-items-center" @submit.prevent="completePassword">
    <div class="form-group w-100">
      <label for="password" class="label">{{ $t('pages.auth.password') }}</label>
      <input
        id="password"
        ref="password"
        class="form-control no-border"
        required
        type="password"
      >
    </div>

    <div class="form-group w-100">
      <label for="confirmPassword" class="label">{{ $t('pages.auth.confirmPassword') }}</label>
      <input
        id="confirmPassword"
        ref="confirmPassword"
        class="form-control no-border"
        required
        type="password"
      >
    </div>

    <b-button
      type="submit"
      size="sm"
      variant="inverse"
      :disabled="inProgress"
    >
      <span>{{ $t('pages.auth.complete-password.submit') }}</span>
      <b-spinner v-if="inProgress" small type="grow" />
      <i v-else class="fa fa-key" />
    </b-button>
  </form>
</template>

<script>
import _ from 'lodash'
import { mapState, mapMutations } from 'vuex'
import { getMessageForKey } from '@/i18n'

export default {
  computed: {
    ...mapState('auth', ['inProgress'])
  },
  destroyed () {
    this.clearCognitoUser()
  },
  methods: {
    ...mapMutations('auth', ['clearCognitoUser']),
    completePassword () {
      const formData = _.mapValues(this.$refs, input => input.value)
      const { password, confirmPassword } = formData
      if (password !== confirmPassword) {
        this.$messageService.showErrorMessage({
          message: getMessageForKey('pages.auth.errors.passwordsMismatch')
        })
      } else {
        this.$gtag.event('completePassword', { method: 'Google' })
        this.$store.dispatch('auth/completePassword', password)
      }
    }
  }
}
</script>

<style src="../Auth.scss" lang="scss" scoped/>
