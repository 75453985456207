<template>
  <section>
    <b-row class="d-flex align-items-end align-self-end">
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="getActiveTokenFiltersCount"
          :title="$tc('widgets.consumption.activeTokens', getActiveTokenFiltersCount || '0')"
        />
      </b-col>
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="metrics.cardsInUse"
          :title="$tc('widgets.chargingSessions.cardsInUse', metrics.cardsInUse || '0')"
        />
      </b-col>
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="total || '0'"
          :title="$t('widgets.consumption.total')"
        />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-end align-self-end">
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="metrics.slow.energy"
          :title="$t('widgets.consumption.normalCharging')"
        />
      </b-col>
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="metrics.fast.energy"
          :title="$t('widgets.consumption.fastCharging')"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import AnimatedTile from '@/components/AnimatedTile/AnimatedTile'

export default {
  name: 'ConsumptionWidget',
  components: { AnimatedTile },
  props: {
    metrics: {
      fast: {
        count: {
          type: Number
        },
        energy: {
          type: Number
        }
      },
      slow: {
        count: {
          type: Number
        },
        energy: {
          type: Number
        }
      },
      cardsInUse: Number
    }
  },
  computed: {
    ...mapGetters('filtering', ['getActiveTokenFiltersCount']),
    total () {
      return this.metrics.slow.energy + this.metrics.fast.energy
    }
  }
}

</script>
