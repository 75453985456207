<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center mx-1 mb-2">
      <h4 class="font-weight-normal">
        {{ $t('mobility.chargePoints') }}
      </h4>
      <export-to-excel-button :loading="excelLoading" @click="export2Excel" />
    </b-row>
    <b-row>
      <b-col xs="12">
        <div class="pb-xlg h-100">
          <div class="table-responsive">
            <v-server-table
              ref="table"
              :columns="columns"
              :options="options"
            />
            <div v-if="loading" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
              <b-spinner class="fetching-indicator" type="grow" label="Spinning" />
            </div>
            <b-button
              v-if="showNextPage"
              class="center load-more-button"
              @click="loadAdditionalData()"
            >
              {{ $t('pages.common.loadMore') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ExportToExcelButton from '@/components/ExportToExcelButton/ExportToExcelButton'
import StatusCell from '../StatusCell'

export default {
  name: 'ChargePointsTable',
  components: {
    ExportToExcelButton
  },
  data () {
    return {
      columns: [
        'id',
        'type',
        'postalCode',
        'address',
        'city',
        'country',
        'status'
      ],
      sortable: [
        'id',
        'type'
      ],
      loading: false,
      excelLoading: false,
      items: [],
      chunk: 25,
      linesCount: 0,
      showNextPage: false
    }
  },
  computed: {
    options () {
      return {
        headings: {
          id: this.$t('pages.emobility.sections.transactions.columns.chargingPointNr'),
          type: this.$t('pages.emobility.sections.chargingTokens.columns.type'),
          postalCode: this.$t('pages.emobility.sections.transactions.columns.postalCode'),
          address: this.$t('pages.emobility.sections.transactions.columns.address'),
          city: this.$t('pages.emobility.sections.transactions.columns.city'),
          country: this.$t('pages.emobility.sections.transactions.columns.country'),
          status: this.$t('pages.emobility.sections.chargingTokens.columns.status')
        },
        columnsDisplay: {
          postalCode: 'min_desktop',
          address: 'min_desktop',
          city: 'min_desktop',
          country: 'min_desktop'
        },
        sortable: this.sortable,
        templates: {
          status: StatusCell
        },
        filterable: false,
        perPage: 1000,
        pagination: { chunk: 25, dropdown: false },
        perPageValues: [],
        texts: {
          limit: '',
          count: '',
          noResults: this.$t('pages.common.noResults')
        },
        skin: 'table',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        },
        requestAdapter: data => {
          return {
            page: data.page - 1,
            size: this.linesCount,
            sort: {
              sortBy: this.getSortByForColumn(data.orderBy),
              sortingDir: data.ascending
            }
          }
        },
        requestFunction: this.loadItems
      }
    }
  },
  created () {
    this.linesCount = this.chunk
  },
  methods: {
    loadItems (data) {
      const emptyResponse = {
        count: 0,
        data: []
      }
      if (this.loading) {
        return Promise.resolve(emptyResponse)
      }
      this.loading = true
      return this.$chargePointService.getChargePoints({
        paging: data
      }).then(res => {
        this.items = res.data = res.data.map(el => {
          return {
            ...el,
            i18nStatus: this.$t(this.getStringForStatus(el.status))
          }
        })
        this.showNextPage = res.hasNextPage
        res.count = this.items.length || 0
        // res.count should mutate here for avoid showing default pagination panel
        return res
        // eslint-disable-next-line no-console
      }).catch(err => {
        console.error(err)
        return emptyResponse
      })
        .finally(() => (this.loading = false))
    },
    export2Excel () {
      const options = {
        model: 'chargepoint',
        fileName: `${this.$t('mobility.chargePoints')}.xlsx`,
        fields: [
          'id',
          'type_name',
          'current_postal_code',
          'current_address',
          'current_city',
          'current_country_iso2',
          'status'
        ],
        headings: [
          this.$t('pages.emobility.sections.transactions.columns.chargingPointNr'),
          this.$t('pages.emobility.sections.chargingTokens.columns.type'),
          this.$t('pages.emobility.sections.transactions.columns.postalCode'),
          this.$t('pages.emobility.sections.transactions.columns.address'),
          this.$t('pages.emobility.sections.transactions.columns.city'),
          this.$t('pages.emobility.sections.transactions.columns.country'),
          this.$t('pages.emobility.sections.chargingTokens.columns.status')
        ]
      }
      this.excelLoading = true
      this.$exportService.exportToExcel(options)
        .finally(() => (this.excelLoading = false))
    },
    getStringForStatus (status) {
      switch (status) {
        case 'INACTIVE':
          return 'pages.emobility.sections.chargingTokens.statuses.inactive'
        case 'ALLOCATED':
          return 'pages.emobility.sections.chargingTokens.statuses.allocated'
        case 'ACTIVE':
          return 'pages.emobility.sections.chargingTokens.statuses.active'
        case 'IN_STOCK':
          return 'pages.emobility.sections.chargingTokens.statuses.in_stock'
        case 'BLOCKED':
          return 'pages.emobility.sections.chargingTokens.statuses.blocked'
        default:
          return ''
      }
    },
    loadAdditionalData () {
      this.linesCount += 25
      this.$refs.table.refresh()
    },
    getSortByForColumn (column) {
      switch (this.columns.indexOf(column)) {
        default:
        case 0:
          return 'id'
        case 1:
          return 'type_name'
      }
    }
  }
}
</script>

<style scoped lang="scss">

h4 {
  margin-bottom: 0;
}
</style>
