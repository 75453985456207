import axios from 'axios'

let http = null

export const settingsService = {
  init ({ token = null }) {
    http = axios.create({
      baseURL: '/account',
      headers: { 'Content-Type': 'application/json' }
    })

    if (token?.jwtToken) {
      http.defaults.headers.common.Authorization = `Bearer ${token.jwtToken}`
    }
  },
  getAccountDetails ({ accountId }) {
    return http.get(`accounts/${accountId}`)
  }
}

if (process.env.VUE_APP_USE_REAL_SERVICES === 'true') {
  window.settings = settingsService
}
