<template>
  <div :class="[{root: true}, dashboardThemeClass, 'sing-dashboard']">
    <div class="wrap" :class="brand">
      <Header />
      <b-alert :show="isImpersonator" variant="success">
        {{ $t('pages.common.impersonatorText') }}
      </b-alert>
      <v-touch class="content" :swipe-options="{direction: 'horizontal'}">
        <transition name="router-animation">
          <router-view />
        </transition>
      </v-touch>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Header from '@/components/Header/Header'
import { labelId } from '@/config'
import { activityWatcher, removeActivityWatcher } from '@/utils/activity-helper'

export default {
  name: 'MobilityLayout',
  components: { Header },
  data: () => ({
    brand: labelId ? labelId.toLowerCase() : '',
    hidden: null,
    visibilityChange: null
  }),
  computed: {
    ...mapState('layout', ['dashboardTheme']),
    ...mapState('auth', ['isImpersonator']),
    dashboardThemeClass: function () {
      return 'dashboard-' + this.dashboardTheme
    }
  },
  mounted () {
    activityWatcher()
  },
  created () {
    this.refreshAuthSession()
    setInterval(() => {
      this.refreshAuthSession()
    }, 30001)
  },
  beforeDestroy () {
    removeActivityWatcher()
  },
  methods: {
    ...mapActions('auth', ['refreshAuthSession'])
  }
}
</script>

<style lang="scss">
@import '../styles/app';

.root {
  height: 100%;
  position: relative;
  left: 0;
  transition: left $sidebar-transition-time ease-in-out;
}

.content {
  position: relative;
  flex-grow: 1;
  padding: 0;
  background-color: $body-bg;

  // Hammers disallows text selection, allowing it for large screens
  @media (min-width: breakpoint-min(sm)) {
    user-select: auto !important;
  }
}

.wrap .alert {
  border-radius: 0;
}
</style>
