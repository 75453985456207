import Labels from '@/label'

let { host } = window.location

export let labelId = Object.values(Labels).find(val => host.includes(val))

const defaultLabel = process.env.VUE_APP_DEFAULT_LABEL

if (!labelId && !!defaultLabel) {
  labelId = Labels[defaultLabel]
}

let prefix = ''
switch (labelId) {
  case Labels.GREENNL:
    prefix = 'GNL_'
    break
  case Labels.PONENERGIE:
    prefix = 'PON_'
    break
  case Labels.SHUTTEL:
    prefix = 'SHT_'
    break
  case Labels.PORSCHE:
    prefix = 'POR_'
    break
  case Labels.DUTCHLEASE:
    prefix = 'DLE_'
    break
  case Labels.EASYOPWEG:
    prefix = 'EOW_'
    break
  case Labels.EENERGIE:
    prefix = 'EEN_'
    break
  case Labels.EPOUWER:
    prefix = 'EPO_'
    break
  case Labels.MOBILITICS:
    prefix = 'MOB_'
    break
  case Labels.TOBEGREEN:
    prefix = '2BG_'
    break
  case Labels.ENIE:
    prefix = 'ENI_'
    break
  case Labels.CUBE:
    prefix = 'CUB_'
    break
  case Labels.PONDEALER:
    prefix = 'PND_'
    break
  case Labels.COOLBLUE:
    prefix = 'CBL_'
    break
  case Labels.BRANDSTOF:
    prefix = 'BRN_'
    break
  case Labels.ALLURECHARGE:
    prefix = 'ALC_'
    break
  case Labels.HOOGENBOOM:
    prefix = 'HGB_'
    break
  case Labels.LAADKRACHT:
    prefix = 'LDK_'
    break
  case Labels.H2E:
    prefix = 'H2E_'
    break
  case Labels.APOINT:
    prefix = 'APN_'
    break
}

export default {
  Auth: {
    region: 'eu-west-1',
    userPoolId: process.env[`VUE_APP_${prefix}POOL_ID`] || '',
    userPoolWebClientId: process.env[`VUE_APP_${prefix}APP_CLIENT_ID`] || '',
    mandatorySignIn: false,
    authenticationFlowType: 'USER_SRP_AUTH',
    clientMetadata: {
      // Custom data so accountservice+cognito lambdas can identify the originating portal
      portal: 'EMOBILITY'
    }
  },
  Analytics: {
    disabled: true
  }
}
