import { labelId } from '@/config'

export function getBrandingForCurrentLabel () {
  if (labelId) {
    return import('../label/' + labelId.toLowerCase())
      .then(module => module.default)
  } else {
    return Promise.reject(new Error('No current label'))
  }
}

function loadDefaultFontBy (fontFaces) {
  Object.keys(fontFaces).forEach((key) => {
    document.fonts.add(fontFaces[key])
    fontFaces[key].load()
  })
}

export function loadFontCssForCurrentLabel () {
  if (labelId) {
    return new Promise(async function (resolve) {
      const fontFaces = await import('../styles/labels/' + labelId.toLowerCase() + '-font.js')
      loadDefaultFontBy(fontFaces.default)
      if (document.fonts) {
        document.fonts.ready.then(() => {
          resolve()
        })
      }
    })
  } else {
    return Promise.reject(new Error())
  }
}

export function loadFaviconForCurrentLabel () {
  const favicon = document.getElementById('favicon')
  if (labelId) {
    favicon.href = `/favicons/${labelId.toLowerCase()}.ico`
  }
}

export function getNameForCurrentLabel () {
  const label = labelId ? labelId.toLowerCase().replace('-', '') : ''
  return `labels.${label}`
}

export function setTitle (title) {
  document.title = title
}
