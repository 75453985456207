<template>
  <b-badge :variant="statusVariant">
    {{ statusLabel }}
  </b-badge>
</template>

<script>

export default {
  name: 'TransactionStatusCell',
  props: ['data', 'index'],
  computed: {
    statusLabel () {
      return this.data.status
    },
    statusVariant () {
      if (this.data.status === 'NEW') {
        return 'warning'
      } else {
        return 'success'
      }
    }
  }
}
</script>
