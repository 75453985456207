/* eslint-disable camelcase */
import Vue from 'vue'
import router from '../../Routes'
import Auth from '@aws-amplify/auth'
import { getMessageForKey } from '@/i18n'
import { loginPath } from '@/Routes'
import store from '@/store'
import { isImpersonator } from '../../utils/auth-helper'

export default {
  logIn ({ commit, dispatch }, credentials) {
    dispatch('startAuthProgress')
    return Auth.signIn(credentials)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          commit('setCognitoUser', user)
          dispatch('finishAuthProgress')
          return Promise.resolve({ flag: 'newPasswordRequired' })
        }
        if (user.signInUserSession?.accessToken?.payload?.['cognito:groups']) {
          const match = true
          // NOTE: uncomment this part when https://github.com/ServiceHouse/emobility-portal/pull/248/files starts working again
          // const match = user.signInUserSession?.accessToken?.payload?.['cognito:groups'].find((role) => {
          //   return role === 'PORTAL_EMOBILITY'
          // })
          if (!match) {
            dispatch('finishAuthProgress')
            dispatch('clearCredentials')
            return Promise.resolve({ flag: 'unauthorized' })
          } else {
            const { given_name, family_name, email } = user.attributes
            store.commit('settings/setName', `${given_name || ''} ${family_name || ''}`)
            store.commit('settings/setUserEmail', email)
            dispatch('finishAuthProgress')
            commit('setUserCredentials', user.signInUserSession)
            return Promise.resolve({ flag: isImpersonator(user) ? 'isImpersonator' : '' })
          }
        } else {
          dispatch('finishAuthProgress')
          dispatch('clearCredentials')
          return Promise.resolve({ flag: 'unauthorized' })
        }
      })
      .catch(err => {
        if (err.code === 'PasswordResetRequiredException') {
          dispatch('forgotPassword', credentials.username)
        } else {
          dispatch('finishAuthProgress')
          Vue.$messageService.showErrorMessage({
            message: getMessageForKey('pages.auth.errors.usernamePasswordIncorrect')
          })
        }
        throw err
      })
  },
  logOut ({ dispatch }) {
    return Auth.signOut()
      .then(() => {
        dispatch('clearCredentials')
        return Promise.resolve()
      })
  },
  clearCredentials ({ commit }) {
    commit('clearUserCredentials')
    localStorage.clear()
    sessionStorage.clear()
  },
  forgotPasswordRedirect ({ commit }) {
    Auth.signOut()
      .then(() => {
        commit('clearUserCredentials')
        localStorage.clear()
        sessionStorage.clear()
        router.push('forgot-password')
      })
  },
  forgotPassword ({ dispatch }, username) {
    dispatch('startAuthProgress')
    Auth.forgotPassword(username)
      .then(() => {
        Vue.$messageService.showInfoMessage({
          message: getMessageForKey('pages.auth.forgot-password.successMessage')
        })
        router.push('/reset-password')
      })
      .catch(err => {
        Vue.$messageService.showErrorMessage({
          message: err.message
        })
      })
      .finally(() => dispatch('finishAuthProgress'))
  },
  resetPassword ({ dispatch }, { username, code, password }) {
    dispatch('startAuthProgress')
    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        router.push(loginPath)
        Vue.$messageService.showInfoMessage({
          message: getMessageForKey('pages.auth.reset-password.successMessage')
        })
      })
      .catch(err => {
        Vue.$messageService.showErrorMessage({
          message: err.message
        })
      })
      .finally(() => dispatch('finishAuthProgress'))
  },
  completePassword ({ dispatch, commit, state }, password) {
    dispatch('startAuthProgress')
    Auth.completeNewPassword(state.cognitoUser, password, null)
      .then(() => {
        commit('clearCognitoUser')
        router.push(loginPath)
        Vue.$messageService.showInfoMessage({
          message: getMessageForKey('pages.auth.complete-password.successMessage')
        })
      })
      .catch(err => {
        Vue.$messageService.showErrorMessage({
          message: err.message
        })
      })
      .finally(() => dispatch('finishAuthProgress'))
  },
  startAuthProgress ({ commit }) {
    commit('setInProgress', true)
  },
  finishAuthProgress ({ commit }) {
    commit('setInProgress', false)
  },
  refreshAuthSession ({ commit }) {
    // Will refresh token if needed.
    Auth.currentSession().then(tokens => {
      commit('setUserCredentials', tokens)
    })
  }
}
