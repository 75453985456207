import axios from 'axios'
import { refreshSessionAndCall, retryExpired } from '@/utils/retryer'
import { mapToInvoices } from '@/business/frontend/Invoice'
import { saveAs } from 'file-saver'
import Vue from 'vue'
import { getMessageForKey } from '../../i18n'

let http = null
let authorized = false

export const invoiceService = {
  get authorized () {
    return authorized
  },

  init ({ token = null }) {
    http = axios.create({
      baseURL: '/invoicing',
      headers: { 'Content-Type': 'application/json' }
    })

    if (token?.jwtToken) {
      http.defaults.headers.common.Authorization = `Bearer ${token?.jwtToken}`
      authorized = true
    }
  },

  setToken ({ token }) {
    if (token) {
      http.defaults.headers.common.Authorization = `Bearer ${token}`
      authorized = true
    } else {
      // eslint-disable-next-line no-console
      console.warn('You tried to set the authorization token without providing one!')
    }
  },

  removeToken () {
    http.defaults.headers.common.Authorization = ''
    authorized = false
  },

  getInvoices ({ page, size, sort: { sortBy, sortingDir }, startDate, endDate }) {
    if (!http?.defaults?.headers?.common?.Authorization) {
      return Promise.reject('No authorization header is set in the invoice service')
    }
    return retryExpired({
      callback: getInvoicesCallback({ page, size, sortBy, sortingDir, startDate, endDate }),
      number: 3,
      wait: 1000
    })
  },

  handleDownloadError (error) {
    Vue.$messageService.showErrorMessage({
      message: getMessageForKey('pages.emobility.errors.downloading')
    })
    // eslint-disable-next-line no-console
    console.error(error)
    throw error
  },

  downloadPDF (invoiceNumber) {
    return http.get(`invoices/${invoiceNumber}`, {
      headers: { 'Content-Type': 'application/pdf' },
      responseType: 'blob'
    })
      .then(response => saveAs(response.data, `invoice_${invoiceNumber}.pdf`))
      .catch(error => this.handleDownloadError(error))
  }
}

const getInvoicesCallback = ({ page = 0, size = 1, sortBy = 'functionalKey_startDate', sortingDir, startDate = '', endDate = '' }) => {
  const callback = onRetry => {
    if (onRetry) {
      return refreshSessionAndCall(callback)
    } else {
      const sort = `${sortBy},${sortingDir ? 'asc' : 'desc'}`
      return http.get('invoices/search/findByAuthorizedUserAsBuyer', {
        params: {
          page,
          size,
          sort,
          startDate,
          endDate
        }
      })
        .then(res => ({
          data: mapToInvoices(res),
          totalElements: res?.data?.totalElements
        }
        ))
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
          throw error // Important to re-throw so the catch in the retry fn can pick it up
        })
    }
  }

  return callback
}

if (process.env.VUE_APP_USE_REAL_SERVICES === 'true') {
  window.invoices = invoiceService
}
