/* eslint-disable camelcase */
import { AbstractService } from '../abstract'
import { mapToLead } from '@/business/frontend/Lead'

class LeadsService extends AbstractService {
  getLeads (params) {
    return this.getData(({ paging, status }) => {
      const { sortingDir, sortBy, page, size } = paging
      const sort = sortBy ? `${sortingDir === 0 ? '-' : ''}${sortBy}` : ''
      return this.http.get('/leads/', {
        params: {
          status: status,
          limit: page ? page * size : size,
          offset: page ? size : 0,
          sort
        }
      }).then(response => {
        const data = response.data
        const results = data.results && data.results.length ? data.results.map(mapToLead) : []
        return {
          data: results,
          hasNextPage: !!data.next
        }
      }).catch(err => {
        console.error(err)
        throw err
      })
    }, params)
  }
}

export const leadsService = new LeadsService({ baseURL: '/mobility2/secured' })
