import axios from 'axios'
import { refreshSessionAndCall, retryExpired } from '@/utils/retryer'

export class AbstractService {
  _authorized = false
  http = false

  constructor ({ baseURL }) {
    this.http = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  get authorized () {
    return this._authorized
  }

  init (token) {
    this._setToken(token)
  }

  _setToken (token) {
    if (token?.jwtToken) {
      this.http.defaults.headers.common.Authorization = `Bearer ${token.jwtToken}`
      this._authorized = true
    } else {
      // eslint-disable-next-line no-console
      console.warn('You tried to set the authorization token without providing one!')
    }
  }

  removeToken () {
    this.http.defaults.headers.common.Authorization = ''
    this._authorized = false
  }

  getData (externalCallback, params) {
    if (!this.http?.defaults?.headers?.common?.Authorization) {
      return Promise.reject(new Error('No authorization header is set'))
    }

    return retryExpired({
      callback: this._callbackWrapper(externalCallback, params),
      number: 3,
      wait: 1000
    })
  }

  _callbackWrapper (callback, params) {
    const action = onRetry => {
      if (onRetry) {
        return refreshSessionAndCall(action)
      } else {
        return callback(params)
      }
    }
    return action
  }
}
