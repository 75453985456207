// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueTouch from 'vue-touch'

import store from './store'
import router from './Routes'
import App from './App'
import axios from 'axios'
import { ClientTable, ServerTable } from 'vue-tables-2'
import Toasted from 'vue-toasted'
import { tokenService } from '@/services/tokens'
import { transactionService } from '@/services/transaction'
import { chargePointService } from '@/services/chargepoint'
import { invoiceService } from '@/services/invoice'
import { messageService } from '@/services/message'
import { exportService } from '@/services/export'
import { sideOperationService } from '@/services/sideOperation'
import { leadsService } from '@/services/leads'
import { cardLeadsService } from '@/services/cardLeads'
import { installationsService } from '@/services/installations'
import { i18n } from '@/i18n'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import awsconfig from './config'
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import { settingsService } from '@/services/settings'
import VueGtag from 'vue-gtag'
import VueGoogleCharts from 'vue-google-charts'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

if (process.env.VUE_APP_SENTRY_ENVIRONMENT && process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    Vue: Vue,
    integrations: [
      new Integrations.BrowserTracing()
    ],
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0
  })
}

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
}, router)

Vue.filter('numFormat', numFormat(numeral))

Vue.use(VueGoogleCharts)
Vue.use(BootstrapVue)
Vue.use(Toasted)
Vue.use(VueTouch)
Vue.use(ClientTable, { theme: 'bootstrap4' })
Vue.use(ServerTable, { theme: 'bootstrap4' })

Vue.config.productionTip = false

Vue.prototype.$http = axios

Vue.$tokenService = tokenService

Object.defineProperty(Vue.prototype, '$tokenService', {
  get () {
    return tokenService
  }
})

Vue.$transactionService = transactionService

Object.defineProperty(Vue.prototype, '$transactionService', {
  get () {
    return transactionService
  }
})

Vue.$chargePointService = chargePointService

Object.defineProperty(Vue.prototype, '$chargePointService', {
  get () {
    return chargePointService
  }
})

Vue.$invoiceService = invoiceService

Object.defineProperty(Vue.prototype, '$invoiceService', {
  get () {
    return invoiceService
  }
})

Vue.$messageService = messageService

Object.defineProperty(Vue.prototype, '$messageService', {
  get () {
    return messageService
  }
})

Vue.$settingsService = settingsService

Object.defineProperty(Vue.prototype, '$settingsService', {
  get () {
    return settingsService
  }
})

Vue.$exportService = exportService

Object.defineProperty(Vue.prototype, '$exportService', {
  get () {
    return exportService
  }
})

Vue.$sideOperationService = sideOperationService

Object.defineProperty(Vue.prototype, '$sideOperationService', {
  get () {
    return sideOperationService
  }
})

Vue.$leadsService = leadsService

Object.defineProperty(Vue.prototype, '$leadsService', {
  get () {
    return leadsService
  }
})

Vue.$cardLeadsService = cardLeadsService

Object.defineProperty(Vue.prototype, '$cardLeadsService', {
  get () {
    return cardLeadsService
  }
})

Vue.$installationsService = installationsService

Object.defineProperty(Vue.prototype, '$installationsService', {
  get () {
    return installationsService
  }
})

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  i18n,
  router,
  render: h => h(App)
})

if (process.env.VUE_APP_USE_REAL_SERVICES === 'false') {
  // eslint-disable-next-line no-console
  console.log('Using mock services')
}
