<template>
  <div class="segmented-control">
    <input
      id="segmented-chargingsessions"
      type="radio"
      name="segmented"
      checked
    >
    <!-- <input type="radio" name="segmented" id="segmented-chargingcosts"> -->
    <input id="segmented-consumption" type="radio" name="segmented">
    <label
      for="segmented-chargingsessions"
      :data-value="$t('mobility.chargingCdrs')"
      :class="{active: view === 'chargingsessions'}"
      @click="changeView('chargingsessions')"
    >
      {{ $t('mobility.chargingCdrs') }}
      <!-- {{ $t('mobility.chargingSessions') }} -->
    </label>
    <!-- <label
      for="segmented-chargingcosts"
      :data-value="$t('mobility.chargingCosts')"
      @click="changeView('chargingcosts')"
      :class="{ active: view === 'chargingcosts' }">
      {{ $t('mobility.chargingCosts') }}
    </label> -->
    <label
      for="segmented-consumption"
      :data-value="$t('mobility.consumption')"
      @click="changeView('consumption')"
    >
      {{ $t('mobility.consumption') }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'SegmentedControls',
  props: {
    parentView: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data: () => ({
    view: ''
  }),
  beforeMount () {
    this.view = this.parentView
  },
  methods: {
    changeView (view) {
      this.view = view
      this.$emit('interface', this.view)
    }
  }
}
</script>

<style scoped lang="scss">
.segmented-control {
  color: var(--segmented-control-color);
  width: 100%;
  position: relative;
  display: inline-block;
  border: 1px solid currentColor;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  overflow: hidden;
  -moz-border-radius: 19px;
  -webkit-border-radius: 19px;
  border-radius: 19px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -o-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -moz-transition-property: none;
  -o-transition-property: none;
  -webkit-transition-property: none;
  transition-property: none;
  -webkit-tap-highlight-color: transparent;
}

label {
  margin-bottom: 0;
}

.segmented-control > input {
  position: absolute;
  left: -10000px;
  opacity: 0;
}

.segmented-control > input[type='checkbox'] ~ label {
  -moz-transition-duration: 0s;
  -o-transition-duration: 0s;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.segmented-control > input[type='checkbox'] ~ label:before {
  opacity: 0;
}
.segmented-control > input:disabled:nth-child(1) ~ label:nth-of-type(1) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):after, .segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):before {
  opacity: 1; }
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1):after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1):before, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1) ~ label:after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1) ~ label:before {
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2):after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2):before, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:before {
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3):after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3):before, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4):after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4):before, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5):after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5):before, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }
.segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6):after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6):before, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segmented-control > input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%); }
.segmented-control > input:disabled:nth-child(2) ~ label:nth-of-type(2) {
  opacity: 0.3;
  cursor: not-allowed; }
.segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):after, .segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):before {
  opacity: 1; }
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2):after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2):before, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:before {
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3):after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3):before, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4):after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4):before, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5):after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5):before, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }
.segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6):after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6):before, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segmented-control > input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }
.segmented-control > input:disabled:nth-child(3) ~ label:nth-of-type(3) {
  opacity: 0.3;
  cursor: not-allowed; }
.segmented-control > input:nth-child(3):checked ~ label:nth-of-type(3):after, .segmented-control > input:nth-child(3):checked ~ label:nth-of-type(3):before {
  opacity: 1; }
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3):after, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3):before, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transform: translateX(200%); }
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4):after, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4):before, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transform: translateX(200%); }
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5):after, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5):before, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transform: translateX(200%); }
.segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6):after, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6):before, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segmented-control > input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}

.segmented-control > input:disabled:nth-child(4) ~ label:nth-of-type(4) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segmented-control > input:nth-child(4):checked ~ label:nth-of-type(4):after, .segmented-control > input:nth-child(4):checked ~ label:nth-of-type(4):before {
  opacity: 1;
}

.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4):after, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4):before, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  -moz-transform: translateX(300%);
  -ms-transform: translateX(300%);
  -webkit-transform: translateX(300%);
  transform: translateX(300%);
}

.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5):after, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5):before, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  -moz-transform: translateX(300%);
  -ms-transform: translateX(300%);
  -webkit-transform: translateX(300%);
  transform: translateX(300%);
}

.segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6):after, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6):before, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segmented-control > input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  -moz-transform: translateX(300%);
  -ms-transform: translateX(300%);
  -webkit-transform: translateX(300%);
  transform: translateX(300%);
}

.segmented-control > input:disabled:nth-child(5) ~ label:nth-of-type(5) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segmented-control > input:nth-child(5):checked ~ label:nth-of-type(5):after, .segmented-control > input:nth-child(5):checked ~ label:nth-of-type(5):before {
  opacity: 1;
}

.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5):after, .segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5):before, .segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  -moz-transform: translateX(400%);
  -ms-transform: translateX(400%);
  -webkit-transform: translateX(400%);
  transform: translateX(400%);
}

.segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6):after, .segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6):before, .segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segmented-control > input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  -moz-transform: translateX(400%);
  -ms-transform: translateX(400%);
  -webkit-transform: translateX(400%);
  transform: translateX(400%);
}

.segmented-control > input:disabled:nth-child(6) ~ label:nth-of-type(6) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segmented-control > input:nth-child(6):checked ~ label:nth-of-type(6):after, .segmented-control > input:nth-child(6):checked ~ label:nth-of-type(6):before {
    opacity: 1;
}

.segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6):after, .segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6):before, .segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segmented-control > input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  -moz-transform: translateX(500%);
  -ms-transform: translateX(500%);
  -webkit-transform: translateX(500%);
  transform: translateX(500%);
}

.segmented-control > label {
  display: inline-block;
  padding: 0 .71em;
  cursor: pointer;
  float: left;
  line-height: 36px;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: inherit;
  -moz-transition-property: none;
  -o-transition-property: none;
  -webkit-transition-property: none;
  transition-property: none;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
}

.segmented-control > label:before, .segmented-control > label:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.segmented-control > label:after {
  color: #fff;
  content: attr(data-value);
  text-align: center;
  padding: inherit;
  z-index: 10;
  font-style: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  opacity: 0;
  -moz-transition-property: opacity, -moz-transform;
  -o-transition-property: opacity, -o-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
}

.segmented-control > label:after, .segmented-control > label {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.segmented-control > label:before {
  content: '';
  color: inherit;
  background: var(--label-color-primary);
  border-radius: 18px;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
}

.segmented-control > label:first-of-type:nth-last-of-type(1), .segmented-control > label:first-of-type:nth-last-of-type(1):after, .segmented-control > label:first-of-type:nth-last-of-type(1):before, .segmented-control > label:first-of-type:nth-last-of-type(1) ~ label, .segmented-control > label:first-of-type:nth-last-of-type(1) ~ label:after, .segmented-control > label:first-of-type:nth-last-of-type(1) ~ label:before {
  width: 100%;
}

.segmented-control > label:first-of-type:nth-last-of-type(2), .segmented-control > label:first-of-type:nth-last-of-type(2):after, .segmented-control > label:first-of-type:nth-last-of-type(2):before, .segmented-control > label:first-of-type:nth-last-of-type(2) ~ label, .segmented-control > label:first-of-type:nth-last-of-type(2) ~ label:after, .segmented-control > label:first-of-type:nth-last-of-type(2) ~ label:before {
  width: 50%;
}

.segmented-control > label:first-of-type:nth-last-of-type(3), .segmented-control > label:first-of-type:nth-last-of-type(3):after, .segmented-control > label:first-of-type:nth-last-of-type(3):before, .segmented-control > label:first-of-type:nth-last-of-type(3) ~ label, .segmented-control > label:first-of-type:nth-last-of-type(3) ~ label:after, .segmented-control > label:first-of-type:nth-last-of-type(3) ~ label:before {
  width: 33.33333%;
}

.segmented-control > label:first-of-type:nth-last-of-type(4), .segmented-control > label:first-of-type:nth-last-of-type(4):after, .segmented-control > label:first-of-type:nth-last-of-type(4):before, .segmented-control > label:first-of-type:nth-last-of-type(4) ~ label, .segmented-control > label:first-of-type:nth-last-of-type(4) ~ label:after, .segmented-control > label:first-of-type:nth-last-of-type(4) ~ label:before {
  width: 25%;
}

.segmented-control > label:first-of-type:nth-last-of-type(5), .segmented-control > label:first-of-type:nth-last-of-type(5):after, .segmented-control > label:first-of-type:nth-last-of-type(5):before, .segmented-control > label:first-of-type:nth-last-of-type(5) ~ label, .segmented-control > label:first-of-type:nth-last-of-type(5) ~ label:after, .segmented-control > label:first-of-type:nth-last-of-type(5) ~ label:before {
  width: 20%;
}

.segmented-control > label:first-of-type:nth-last-of-type(6), .segmented-control > label:first-of-type:nth-last-of-type(6):after, .segmented-control > label:first-of-type:nth-last-of-type(6):before, .segmented-control > label:first-of-type:nth-last-of-type(6) ~ label, .segmented-control > label:first-of-type:nth-last-of-type(6) ~ label:after, .segmented-control > label:first-of-type:nth-last-of-type(6) ~ label:before {
  width: 16.66667%;
}

.segmented-control.italic, .segmented-control .italic {
  font-style: italic;
}

.segmented-control.bold, .segmented-control .bold {
  font-weight: bold;
}

.segmented-control.underline, .segmented-control .underline {
  text-decoration: underline;
}

.segmented-control.line-through, .segmented-control .line-through {
  text-decoration: line-through;
}
</style>
