<template>
  <b-button class="button" :disabled="loading" @click="click">
    <img
      v-if="loading"
      src="@/assets/spinner.svg"
      alt="Spinner"
      class="loader"
    >
    <i v-else class="fa fa-download" />
    <span class="d-none d-sm-inline ml-2">{{ $t('pages.common.exportToExcel') }}</span>
  </b-button>
</template>

<script>
export default {
  name: 'ExportToExcelButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
<style scoped lang="scss">
  @import '../../styles/app';

  .button, .btn-secondary.btn-secondary.btn-secondary {
    background-color: var(--export-button-background-color);
    border: var(--export-button-border);
    color: $black;
    font-size: 1.05rem;
    padding: 0.4rem 1rem;
    display: flex;
    align-items: center;
    transition: all 0.15s;

    i {
      font-size: 1.45rem;
    }

    &:not(.disabled):hover, &:not(.disabled):active, &:not(.disabled):focus, &:focus-within, &:visited {
      background-color: var(--label-color-primary);
      color: $white;
      border: var(--export-button-border);
    }

    span {
      position: relative;
      top: 1px;
    }
    .loader {
      height: 18px;
    }
  }

</style>
