/* eslint-disable camelcase */
import formatMoney from 'accounting-js/lib/formatMoney.js'
import moment from 'moment'

export function mapToSideOperation ({ visible_card_number, product_name_or_type, start, start_location, ext_declaration_text, netto_amount }) {
  return {
    cardNumber: visible_card_number,
    productName: product_name_or_type,
    start: moment(start).locale('nl').format('DD-MM-YYYY, h:mm:ss'),
    startLocation: start_location,
    declarationText: ext_declaration_text,
    cost: formatMoney(netto_amount, { symbol: '€', precision: 2, thousand: '.', decimal: ',' })
  }
}
