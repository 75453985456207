import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import layout from './layout'
import tokens from './tokens'
import filtering from './filtering'
import auth from './auth'
import settings from './settings'
import transactions from './transactions'
import chargePoints from './chargepoints'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'settings']
})

export default new Vuex.Store({
  modules: {
    layout,
    tokens,
    filtering,
    auth,
    settings,
    transactions,
    chargePoints
  },
  plugins: [vuexLocal.plugin]
})
