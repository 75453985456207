export default {
  yes: 'ja',
  no: 'nee',
  lastName: 'Achternaam',
  banner: {
    title: 'Bekijk je laadsessies in Mijn Coolblue.',
    description: 'In Mijn Coolblue zie je, net zoals in het oude portaal al je laadsessies, bekijk je alle facturen en voeg je een laadpas toe.',
    firstPoint: 'Ga naar Mijn Coolblue via de groene knop.',
    secondPoint: 'Log in met het e-mailadres waarop je onze facturen ontvangt.\nHeb je nog geen Coolblue account op dat e-mailadres? Maak dan er dan eentje aan. Het kan tot 24 uur duren voordat je je laadsessies kunt zien.',
    info: 'Kom je er niet uit? Neem dan gerust ',
    link: 'contact met ons op.',
    btnText: 'Ga naar het nieuwe portaal'
  },
  widgets: {
    chargingSessions: {
      activeTokens: 'Actieve pas | Actieve passen',
      sessions: 'Laadsessies',
      cdrs: 'Laadtransactie | Laadtransacties',
      cardsInUse: 'Kaart in gebruik | Kaarten in gebruik',
      normalCharging: 'Normaalladen',
      fastCharging: 'Snelladen'
    },
    chargingCosts: {
      sessions: 'Laadsessies',
      total: 'Totaal €',
      normalCharging: '€ Normaal laden',
      fastCharging: '€ Snelladen'
    },
    consumption: {
      activeTokens: 'Actieve pas | Actieve passen',
      total: 'Totaal kWh',
      normalCharging: 'Normaalladen kWh',
      fastCharging: 'Snelladen kWh'
    }
  },
  mobility: {
    card: 'Laadpas',
    cards: 'Laadpassen',
    cdrs: 'CDRs',
    selectCards: 'Selecteer Laadpassen',
    chargingSessions: 'Laadsessies stations',
    chargingCdrs: 'Laadtransacties passen',
    chargingCosts: 'Laadkosten',
    chargePoints: 'Laadstations',
    chargingCards: 'Laadpassen',
    consumption: 'Consumptie in kWh',
    invoices: 'Facturen',
    dateRange: 'Begindatum - Einddatum'
  },
  sideOperations: {
    title: 'Kosten Parkeren en OV',
    type: 'Soort',
    cardNumber: 'Kaartnummer',
    startsOn: 'Datum en Tijd',
    startLocation: 'Locatie',
    declarationText: 'Omschrijving',
    rawCostsExVat: 'Kosten excl. btw'
  },
  leads: {
    title: 'Leads',
    dealerLocation: 'Dealervestiging',
    advisorName: 'Naam Leadadvisor',
    leadName: 'Naam Lead',
    contractNumber: 'Contractnummer',
    buyOrLease: 'Koop/Lease',
    createdAt: 'Nieuw',
    inspectionFormSent: 'Schouwformulier Verstuurd',
    inspectionFormReceived: 'Schouwdetails Ontvangen',
    offerSent: 'Offerte Verstuurd',
    status: 'Gewonnen/verloren',
    contractDate: 'Contractdatum',
    productChoice: 'Producten',
    submit: 'Lead Verzenden',
    statuses: {
      title: 'Status',
      non: 'Niet geselecteerd',
      inProgress: 'In behandeling',
      won: 'Gewonnen',
      lost: 'Verloren'
    },
    types: {
      buy: 'Koop',
      lease: 'Lease'
    },
    products: {
      none: 'Onbekend',
      chargePoint: 'Alleen laadpunt',
      card: 'Aleen laadpas',
      chargePointAndCard: 'Laadpunt & -pas'
    }
  },
  cardLeads: {
    title: 'Laadpas Leads',
    requestDate: 'Aanvraagdatum',
    registrationNumber: 'Registratienummer',
    name: 'Naam',
    company: 'Bedrijfsnaam',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    statuses: {
      requested: 'Aangevraagd',
      sent: 'Verstuurd naar klant',
      activated: 'Actief'
    }
  },
  installations: {
    title: 'Installatie',
    dealerLocation: 'Leasemaatschappij',
    advisorName: 'Naam Leadadviseur',
    driverName: 'Berijder',
    contractNumber: 'Contractnummer',
    contractDate: 'Contractdatum',
    cpDeliveryDateCommunicated: 'Leverdatum Afgestemd',
    cpDeliveryDate: 'Gewenste Leverdatum',
    cpOrdered: 'Laadstation Besteld',
    cpInstallationDate: 'Installatiedatum Laadpunt'
  },
  pages: {
    common: {
      welcomeMessage: 'Welkom',
      welcomeMessageName: 'Welkom, {givenName}!',
      logout: 'Uitloggen',
      exportToExcel: 'Exporteren naar Excel',
      noResults: 'Geen gegevens beschikbaar',
      loading: 'Laden...',
      portal: 'Portaal',
      loadMore: 'Meer Laden',
      selectAll: 'Selecteer alles',
      search: 'Zoeken',
      backToMobility: 'Terug naar Mijn elektrisch laden',
      impersonatorText: 'Je gebruikt dit als een impersonator',
      sentByEmail: 'Gezien de omvang van het bestand wordt deze gemaild naar het emailadres van dit account.'
    },
    emobility: {
      title: 'Mijn elektrisch laden',
      errors: {
        loading: {
          general: 'Er heeft zich een fout voorgedaan bij het laden van de informatie',
          transactions: 'Er heeft zich een fout voorgedaan bij het laden van de laadsessies',
          chargingTokens: 'Er heeft zich een fout voorgedaan bij het laden van de laadpassen',
          chargePoints: 'Er heeft zich een fout voorgedaan bij het laden van de oplaadpunten',
          invoices: 'Er heeft zich een fout voorgedaan bij het laden van de facturen'
        },
        downloading: 'Er is een fout opgetreden tijdens het downloaden van het bestand van de server',
        sessionExpired: 'Uw sessie is verlopen omdat er meer dan 30 minuten geen activiteit is waargenomen'
      },
      sections: {
        dashboard: {
          title: 'Dashboard',
          tabs: {
            transactions: {
              title: 'Laadsessies',
              sessions: 'Laadsessies'
            },
            kwh: {
              title: 'kWh',
              totalAmount: 'Totaal',
              unit: 'kWh'
            },
            common: {
              activeTokens: 'Passen in gebruik',
              acCharging: 'Laden',
              acChargingShort: 'Laden',
              dcCharging: 'Snelladen',
              dcChargingShort: 'Snelladen'
            }
          },
          filtering: {
            title: 'Filter op',
            byDate: {
              title: 'Filter op datum',
              noFilter: 'Geen filters'
            },
            byToken: {
              title: 'Filter op laadpas',
              placeholder: 'Selecteer een laadpas...',
              noFilter: 'Geen filter toegepast',
              zeroToken: 'U hebt geen laadpas.',
              oneToken: 'U hebt maar één laadpas.'
            }
          }
        },
        transactions: {
          title: 'Laadsessies',
          columns: {
            startTime: 'Start datum en tijd',
            duration: 'Duur',
            kWh: 'kWh',
            chargingPointNr: 'Laadstation ID',
            chargingTokenNr: 'Laadpas ID',
            address: 'Adres',
            postalCode: 'Postcode',
            city: 'Plaats',
            country: 'Land',
            status: 'Status',
            tariff: 'Tarieftype'
          },
          status: {
            inProgress: 'In behandling',
            approved: 'Goedgekeurd'
          }
        },
        chargingTokens: {
          title: 'Laadpassen',
          columns: {
            chargingTokenNr: 'Laadpas ID',
            type: 'Type',
            userName: 'Gebruiker',
            status: 'Status',
            totalAmount: 'Totaal Bedrag',
            usedAmount: 'Gebruikt Bedrag',
            leftAmount: 'Resterend Bedrag',
            isPromotion: 'Actiepas'
          },
          types: {
            chargingCard: 'Laadpas',
            keyChain: 'Laaddruppel'
          },
          statuses: {
            inactive: 'Inactief',
            allocated: 'Toegewezen',
            delivered: 'Geleverd',
            active: 'Actief',
            blocked: 'Geblokkeerd',
            ordered: 'Besteld',
            in_stock: 'Op Voorraad'
          }
        },
        invoices: {
          title: 'Facturen',
          columns: {
            invoiceNumber: 'Factuurnummer',
            invoicePeriod: 'Factuurperiode',
            taxExclusiveAmount: 'Factuurbedrag (excl. btw)',
            taxAmount: 'btw',
            taxInclusiveAmount: 'Factuurbedrag (incl. btw)',
            download: 'Factuur',
            reversed: 'Teruggedraaid',
            credit: 'Credit'
          }
        }
      }
    },
    auth: {
      email: 'E-mail',
      username: 'Gebruikersnaam',
      password: 'Wachtwoord',
      verificationCode: 'Verificatiecode',
      confirmPassword: 'Bevestig wachtwoord',
      resetPassword: 'Reset wachtwoord',
      login: {
        title: 'Welkom',
        tip: 'Hier kunt u inloggen met uw gebruikersnaam en uw wachtwoord',
        forgotPassword: 'Wachtwoord vergeten',
        submit: 'INLOGGEN'
      },
      'forgot-password': {
        title: 'Wachtwoord vergeten',
        tip: 'Voer uw gebruikersnaam in',
        submit: 'BEVESTIG',
        successMessage: 'Wachtwoord opnieuw instellen e-mail is verstuurd, controleer uw mailbox a.u.b.'
      },
      'activate-account': {
        title: 'Account activeren',
        tip: 'Voer uw gebruikersnaam in',
        submit: 'ACTIVEREN',
        successMessage: 'Account activatie e-mail is verstuurd, controleer uw mailbox a.u.b.'
      },
      'reset-password': {
        title: 'Wachtwoord aanmaken',
        tip: 'Voer de wachtwoord opnieuw instellen verificatiecode in, die u per e-mail heeft ontvangen',
        submit: 'WACHTWOORD AANMAKEN',
        successMessage: 'Wachtwoord opnieuw instellen is gelukt, u kan nu inloggen'
      },
      'complete-password': {
        title: 'Nieuw wachtwoord',
        tip: 'Stel a.u.b. een nieuw wachtwoord in voordat u voor de eerste keer inlogt',
        successMessage: 'Wachtwoord wijzigen is gelukt, u kan nu inloggen',
        submit: 'WACHTWOORD INSTELLEN'
      },
      errors: {
        usernamePasswordIncorrect: 'Gebruikersnaam en wachtwoord zijn onjuist!',
        passwordsMismatch: 'Wachtwoorden moeten overeen komen',
        wrongReferrer: 'Inloggen als impersonator kan niet. Om als impersonator in te loggen moet je dat aanklikken vanuit Multiforce'
      }
    },
    mySettings: {
      title: 'Mijn instellingen',
      myDetails: 'Mijn gegevens',
      adjust: 'Aanpassen',
      account: {
        title: 'Account',
        name: 'Naam'
      },
      contactDetails: {
        title: 'Contactgegevens',
        phone: 'Telefoon',
        website: 'Website',
        accountNumber: 'Rekening nummer'
      },
      language: 'Taal'
    }
  },
  labels: {
    greennl: 'GreenNL',
    ponenergie: 'PonEnergie',
    shuttel: 'Shuttel',
    porsche: 'Porsche',
    dutchlease: 'DutchLease',
    easyopweg: 'e-asy op weg',
    eenergie: 'E-Energie',
    bgreen: '2B Green',
    epouwer: 'e-Pouwer',
    mobilitics: 'Mobilitics',
    enie: 'Enie',
    cube: 'Cube',
    pondealer: 'Pon Dealer',
    coolblue: 'Coolblue',
    brandstof: 'Brandstof',
    allurecharge: 'AllureCharge',
    hoogenboom: 'Powered by Hoogenboom',
    laadkracht: 'Laadkracht',
    h2e: 'H2E Power',
    apoint: 'A-Point'
  },
  languages: {
    en: 'English',
    nl: 'Nederlands'
    // de: 'Deutsch'
  }
}
