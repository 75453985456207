import 'moment/locale/nl'
import formatMoney from 'accounting-js/lib/formatMoney.js'
const moment = require('moment')

export class Invoice {
  constructor ({
    invoicePeriod,
    vatExclusiveAmount,
    vatAmount,
    vatInclusiveAmount,
    invoiceNumber,
    reversed,
    credit
  }) {
    Object.assign(this, {
      invoicePeriod,
      vatExclusiveAmount,
      vatAmount,
      vatInclusiveAmount,
      invoiceNumber,
      reversed,
      credit
    })
  }
}

export function mapToInvoices (invoiceResponse) {
  const res = invoiceResponse.data
  return res.content.map(({
    startDate,
    toDate,
    invoiceNumber,
    monetaryTotal,
    reversed
  }) => {
    let { vatExclusiveAmount, vatAmount, vatInclusiveAmount, credit } = monetaryTotal
    const start = moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
    const end = moment(toDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
    return new Invoice({
      invoicePeriod: `${start} - ${end}`,
      vatExclusiveAmount: formatMoney(vatExclusiveAmount, { symbol: '€', precision: 2, thousand: '.', decimal: ',' }),
      vatAmount: formatMoney(vatAmount, { symbol: '€', precision: 2, thousand: '.', decimal: ',' }),
      vatInclusiveAmount: formatMoney(vatInclusiveAmount, { symbol: '€', precision: 2, thousand: '.', decimal: ',' }),
      invoiceNumber,
      reversed,
      credit
    })
  })
}
