<template>
  <div class="settings-main-container">
    <div class="settings-page-title">
      {{ $t('pages.mySettings.myDetails') }}
    </div>

    <div class="settings-card">
      <div class="settings-card-header">
        <h4 class="font-weight-normal settings-card-title">
          {{ $t('pages.mySettings.account.title') }}
        </h4>
      </div>

      <div class="settings-field-container">
        <span v-if="checkName()" class="settings-field">{{ $t('pages.mySettings.account.name') }}:</span>
        <span v-if="checkName()">{{ name }}</span>

        <span class="settings-field">{{ $t('pages.auth.email') }}:</span>
        <span>{{ email }}</span>
      </div>

      <div class="settings-field-container">
        <span class="settings-field" />
        <span :class="{blocked: isImpersonator}" class="reset-link" @click="forgotPassword()">
          <a>{{ $t('pages.auth.resetPassword') }}</a>
        </span>
      </div>
    </div>

    <div class="settings-card">
      <div class="settings-card-header">
        <h4 class="font-weight-normal settings-card-title">
          {{ $t('pages.mySettings.language') }}
        </h4>
      </div>

      <div class="settings-field-container">
        <span>
          <b-button-group size="sm">
            <b-button
              v-for="lang in getLanguages"
              :key="lang.key"
              :class="[isLanguageSelected(lang.key) ? 'primary-background-button' : 'button' ]"
              variant="light"
              @click="changeLanguage(lang.key)"
            >
              {{ lang.value }}
            </b-button>
          </b-button-group>
        </span>
      </div>
    </div>

    <primary-button @click="goToDashboard">
      <span>{{ $t('pages.common.backToMobility') }}</span>
    </primary-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import router from '@/Routes'

export default {
  name: 'MySettings',
  components: {
    PrimaryButton
  },

  computed: {
    ...mapState('settings', ['name', 'userEmail', 'accountEmail']),
    ...mapState('auth', ['isImpersonator']),
    email () {
      return this.accountEmail || this.userEmail
    },
    getLanguages () {
      return Object.entries(this.$t('languages')).map(([key, value]) => ({ key, value }))
    }
  },
  created () {
    Vue.$settingsService.init({ token: this.token })
  },
  mounted () {
    this.getAccountDetails()
  },
  methods: {
    ...mapActions('settings', ['getAccountDetails']),
    ...mapActions('auth', ['forgotPasswordRedirect']),
    changeLanguage (languageKey) {
      this.$i18n.locale = languageKey
      window.localStorage.setItem('savedLanguage', languageKey)
    },
    isLanguageSelected (languageKey) {
      return languageKey === window.localStorage.getItem('savedLanguage')
    },
    adjust () {},
    checkName () {
      if (typeof (this.name) !== 'undefined' && this.name !== null && this.name !== 'undefined undefined') {
        return true
      }
    },
    forgotPassword () {
      if (!this.isImpersonator) {
        this.forgotPasswordRedirect()
      }
    },
    goToDashboard () {
      router.push('/', () => {})
    }
  }
}
</script>

<style src="./MySettings.scss" lang="scss" scoped />
