import Vue from 'vue'
import { getMessageForKey } from '@/i18n'

export default {
  async loadTokens ({ commit }) {
    try {
      const { data } = await Vue.$tokenService.getTokens()
      commit('saveTokens', data)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      Vue.$messageService.showErrorMessage({
        message: getMessageForKey('pages.emobility.errors.loading.chargingTokens')
      })
    }
  }
}
