/* eslint-disable camelcase */
import { AbstractService } from '../abstract'
import { mapToCardLead } from '@/business/frontend/CardLead'

class CardLeadsService extends AbstractService {
  getCardLeads (params) {
    return this.getData(({ paging, startDate, stopDate, lastName, email }) => {
      const { sortingDir, sortBy, page, size } = paging
      const sort = sortBy ? `${sortingDir === 0 ? '-' : ''}${sortBy}` : ''
      return this.http.get('/cardleads/', {
        params: {
          form_received_at_after: startDate ? startDate.split('T')[0] : null,
          form_received_at_before: stopDate ? stopDate.split('T')[0] : null,
          last_name__icontains: lastName,
          email__icontains: email,
          limit: page ? page * size : size,
          offset: page ? size : 0,
          sort
        }
      }).then(response => {
        const data = response.data
        const results = data.results && data.results.length ? data.results.map(mapToCardLead) : []
        return {
          data: results,
          hasNextPage: !!data.next
        }
      }).catch(err => {
        console.error(err)
        throw err
      })
    }, params)
  }
}

export const cardLeadsService = new CardLeadsService({ baseURL: '/mobility2/secured' })
