/* eslint-disable camelcase */
export class ChargePoint {
  constructor ({
    id,
    type,
    status,
    postalCode,
    address,
    city,
    country
  }) {
    Object.assign(this, {
      id,
      type,
      status,
      postalCode,
      address,
      city,
      country
    })
  }
}

export function mapToChargePoints (response) {
  return response?.data?.results?.map(({
    id,
    type_name,
    current_lifecycle_status,
    location
  }) => {
    return new ChargePoint({
      id,
      type: type_name,
      status: current_lifecycle_status,
      postalCode: location?.postal_code,
      address: location?.address,
      city: location?.city,
      country: location?.country?.iso2
    })
  })
}
