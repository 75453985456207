<template>
  <div class="banner">
    <img class="banner__img" src="@/assets/banner.png">
    <div class="banner__wrapper">
      <h1 class="banner__title">
        {{ $t('banner.title') }}
      </h1>
      <p class="banner__desciption">
        {{ $t('banner.description') }}
      </p>
      <ol class="banner__list">
        <li>
          {{ $t('banner.firstPoint') }}
        </li>
        <li>
          {{ $t('banner.secondPoint') }}
        </li>
        <li>
          {{ $t('banner.info') }}<a class="banner__contact-link" href="https://www.coolblue.nl/laadpalen/contact">{{ $t('banner.link') }}</a>
        </li>
      </ol>
      <a class="banner__redirect-link" href=" https://www.coolblue.nl/mijn-coolblue-account/energie/laadpalen">
        <span>
          {{ $t('banner.btnText') }}
        </span>
        <img class="banner__icon" src="@/assets/endIcon.svg">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "DobraSlab Medium";
  font-display: swap;
  src: local("DobraSlab Medium"), url('@/fonts/coolblue-fonts/DobraSlab-Medium.otf');
}

.banner {
  display: flex;
  flex-direction: column;
  max-height: 591px;
  width: 504px;
  background-color: #FFFFFF;
  border-radius: 8px;
  color: #111111;

  &__img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-height: 219px;
  }

  &__wrapper {
    font-family: 'Open Sans';
    display: flex;
    flex-direction: column;
    padding: 1.8rem;
    font-size: 13px;
    line-height: 18.98px;
  }

  &__title {
    font-family: "DobraSlab Medium";
    color: #285DAB;
    font-weight: 500;
    line-height: 28.75px;
    font-size: 23px;
    margin-bottom: 8px;
  }

  &__desciption {
    margin-bottom: 8px;
  }

  ol li {
    list-style: decimal;
    list-style-position: inside;
    margin-bottom: 8px;
    white-space: pre-line;
    text-indent: -1em;
    padding-left: 1em;

    &:last-of-type {
      list-style: none;
    }
  }

  &__contact-link:hover {
    text-decoration: none;
  }

  &__redirect-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 14px 16px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #00B900;
    cursor: pointer;
    border-radius: 8px;
    width: 100%;

    &:hover {
      text-decoration: none;
      color: #FFFFFF;
    }
  }

  &__icon {
    width: 20px;
  }
}

</style>
