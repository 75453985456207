<template>
  <div>
    <b-row class="display-flex justify-content-between mx-1 mb-2">
      <h4 class="font-weight-normal">
        {{ $t('pages.emobility.sections.invoices.title') }}
      </h4>
    </b-row>
    <b-row>
      <b-col xs="12">
        <div class="pb-sm h-100 table-responsive">
          <v-server-table ref="table" :columns="columns" :options="options">
            <template slot="reversed" slot-scope="props">
              {{ props.row.reversed ? $t('yes') : $t('no') }}
            </template>
            <template slot="credit" slot-scope="props">
              {{ props.row.credit ? $t('yes') : $t('no') }}
            </template>
            <b-button
              slot="download"
              slot-scope="props"
              variant="light"
              class="download-btn"
              @click="downloadPDF(props.row.invoiceNumber)"
            >
              <i class="fa fa-download" />
            </b-button>
            <div
              slot="downloadMobile"
              slot-scope="props"
              class="container-amount-download-mobile"
            >
              <div class="mobile-amount">
                {{ props.row.vatInclusiveAmount }}
              </div>
              <div>
                <b-button variant="light" class="download-btn-mobile" @click="downloadPDF(props.row.invoiceNumber)">
                  <i class="fa fa-download" />
                </b-button>
              </div>
            </div>
          </v-server-table>
          <b-button
            v-if="totalItems > linesCount"
            variant="outline-primary"
            class="center"
            @click="loadAdditionalData()"
          >
            {{ $t('pages.common.loadMore') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'InvoicesTable',
  data () {
    return {
      columns: ['invoiceNumber', 'invoicePeriod', 'vatExclusiveAmount', 'vatAmount', 'vatInclusiveAmount', 'reversed', 'credit', 'download', 'downloadMobile'],
      sortable: ['invoiceNumber', 'invoicePeriod', 'vatInclusiveAmount'],
      linesCount: 25,
      invoices: [],
      totalItems: 0
    }
  },
  computed: {
    ...mapState('filtering', ['dateFilter', 'tokenFilters']),
    ...mapGetters('filtering', ['getDateFilter']),
    options () {
      return {
        headings: {
          invoiceNumber: this.$t('pages.emobility.sections.invoices.columns.invoiceNumber'),
          invoicePeriod: this.$t('pages.emobility.sections.invoices.columns.invoicePeriod'),
          vatExclusiveAmount: this.$t('pages.emobility.sections.invoices.columns.taxExclusiveAmount'),
          vatAmount: this.$t('pages.emobility.sections.invoices.columns.taxAmount'),
          vatInclusiveAmount: this.$t('pages.emobility.sections.invoices.columns.taxInclusiveAmount'),
          reversed: this.$t('pages.emobility.sections.invoices.columns.reversed'),
          credit: this.$t('pages.emobility.sections.invoices.columns.credit'),
          download: this.$t('pages.emobility.sections.invoices.columns.download')
        },
        columnsDisplay: {
          vatExclusiveAmount: 'min_desktop',
          vatAmount: 'min_desktop',
          vatInclusiveAmount: 'min_tablet',
          reversed: 'min_tablet',
          credit: 'min_tablet',
          download: 'min_tablet',
          downloadMobile: 'max_mobileL'
        },
        sortable: this.sortable,
        cellClasses: {
          vatExclusiveAmount: [
            {
              class: 'column-align-right',
              condition: () => true
            }
          ],
          vatAmount: [
            {
              class: 'column-align-right',
              condition: () => true
            }
          ],
          vatInclusiveAmount: [
            {
              class: 'column-align-right',
              condition: () => true
            }
          ] },
        filterable: false,
        perPage: 1000,
        perPageValues: [],
        // is needed to hide table size options selector
        orderBy: {
          column: 1,
          ascending: false
        },
        pagination: { chunk: 10, dropdown: false },
        texts: {
          limit: '',
          count: '',
          noResults: this.$t('pages.common.noResults')
        },
        skin: 'table',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        },
        requestAdapter: data => {
          return {
            page: data.page - 1,
            size: this.linesCount,
            startDate: this.dateFilter ? this.dateFilter.start : '',
            endDate: this.dateFilter ? this.dateFilter.end : '',
            sort: {
              sortBy: this.getSortByForColumn(data.orderBy),
              sortingDir: data.ascending
            }
          }
        },
        requestFunction: this.loadInvoices,
        responseAdapter: res => {
          this.invoicesLoaded(res)
          return {
            count: res?.count || 0,
            data: res?.data || []
          }
        }
      }
    }
  },
  watch: {
    dateFilter () {
      this.$refs.table.refresh()
    }
  },
  methods: {
    getSortByForColumn (column) {
      switch (column) {
        case 'invoiceNumber':
          return 'invoiceNumber'
        case 'invoicePeriod':
          return 'functionalKey_startDate'
        case 'vatInclusiveAmount':
          return 'monetaryTotal.vatInclusiveAmount'
      }
    },
    loadInvoices (data) {
      const emptyResponse = {
        count: 0,
        data: []
      }
      return this.$invoiceService.getInvoices(data)
        .then(res => {
          this.totalItems = res.totalElements
          res.count = res.data.length
          return res
        })
        .catch(err => {
          console.error(err)
          return (emptyResponse)
        })
    },
    loadAdditionalData () {
      this.linesCount += 25
      this.$refs.table.refresh()
    },
    invoicesLoaded (res) {
      this.invoices = res?.data
    },
    downloadPDF (invoiceNumber) {
      this.$invoiceService.downloadPDF(invoiceNumber)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/app';

h4 {
  margin-bottom: 0;
}

.center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.download-btn {
  height: 30px;
  width: 46px;
  border: none;
  color: $gray-600;
  background-color: transparent;
  transition: all 0.15s;

  &:hover, &:active {
    background-color: var(--label-color-primary);
    color: $white;
  }

  i {
    position: relative;
    top: 1px;
  }
}

.download-btn-mobile {
  height: 30px;
  width: 36px;
  border: none;
  color: $gray-600;
  background-color: transparent;
  transition: all 0.15s;

  &:hover, &:active {
    background-color: var(--label-color-primary);
    color: $white;
  }

  i {
    position: relative;
    top: 1px;
    left: -2px;
  }
}

.container-amount-download-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mobile-amount {
    width: 64px;
    text-align: right;
  }
}

</style>
