<template>
  <b-button class="button" @click="click">
    <slot name="icon" />
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'PrimaryButton',
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/app';

.button, .btn-secondary.btn-secondary.btn-secondary {
  background-color: var(--export-button-background-color);
  border: var(--export-button-border);
  color: $black;
  font-size: 1.05rem;
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  transition: all 0.15s;

  i {
    font-size: 1.45rem;
  }

  &:hover, &:active, &:focus, &:focus-within, &:visited {
    background-color: var(--label-color-primary);
    color: $white;
    border: var(--export-button-border);
  }

  span {
    position: relative;
    top: 1px;
  }
}
</style>
