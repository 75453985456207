export default {
  setInProgress: (state, payload) => (state.inProgress = payload),
  setUserCredentials: (state, response) => {
    state.token = response.idToken
    state.accessToken = response.accessToken
    state.refreshToken = response.refreshToken
    state.relationId = response?.idToken?.payload?.['custom:relation_id']
    state.accountId = response?.idToken?.payload?.['custom:account_id']
    state.isImpersonator = response?.idToken?.payload?.['custom:is_impersonator'] === 'true'
    state.userGivenName = response?.idToken?.payload?.['given_name']
    state.userGroups = response?.accessToken?.payload?.['cognito:groups'] || []
  },
  clearUserCredentials: state => {
    state.token = null
    state.accessToken = null
    state.refreshToken = null
    state.relationId = null
    state.accountId = null
    state.userGivenName = null
    state.isImpersonator = false
    state.userGroups = []
  },
  setCognitoUser: (state, cognitoUser) => (state.cognitoUser = cognitoUser),
  clearCognitoUser: state => (state.cognitoUser = null)
}
