/* eslint-disable camelcase */
import 'moment/locale/nl'

import formatNumber from 'accounting-js/lib/formatNumber.js'
const moment = require('moment')

export class Transaction {
  constructor ({
    uuid,
    startTime,
    endTime,
    duration,
    energy,
    chargingPointNr,
    chargingTokenNr,
    address,
    city,
    country,
    status,
    tariffType
  }) {
    this.formattedEnergy = formatNumber(energy, { precision: 4, thousand: '.', decimal: ',' })
    Object.assign(this, {
      uuid,
      startTime,
      endTime,
      duration,
      energy,
      chargingPointNr,
      chargingTokenNr,
      address,
      city,
      country,
      status,
      tariffType
    })
  }
}

export function mapToSessions (sessionsResponse) {
  const data = sessionsResponse?.data?.details
  return data.map(session => {
    const {
      uuid,
      start,
      end,
      duration,
      energy,
      charge_point_c,
      visual_id,
      location,
      status,
      tariff_type
    } = session
    return new Transaction({
      uuid,
      startTime: moment(start).locale('nl').format('DD-MM-YYYY, h:mm:ss'),
      endTime: moment(end).locale('nl').format('DD-MM-YYYY, h:mm:ss'),
      duration: duration.split('.')[0],
      energy: energy,
      chargingPointNr: charge_point_c,
      chargingTokenNr: visual_id,
      address: location?.address,
      city: location?.city,
      country: location?.country.iso2,
      status: status,
      tariffType: tariff_type
    })
  })
}

export function mapToTransactions (transactionResponse) {
  const data = transactionResponse?.data?.details || transactionResponse?.data?.results
  return data.map(cpv => {
    const {
      uuid,
      start_date,
      end_date,
      duration,
      location,
      status,
      card,
      energy,
      tariff_type,
      connector
    } = cpv
    return new Transaction({
      uuid,
      startTime: moment(start_date).locale('nl').format('DD-MM-YYYY, h:mm:ss'),
      endTime: moment(end_date).locale('nl').format('DD-MM-YYYY, h:mm:ss'),
      duration: duration.split('.')[0],
      energy: energy,
      chargingPointNr: connector?.evse?.id || '',
      chargingTokenNr: card,
      address: location.address,
      city: location.city,
      country: location?.country?.iso2 || '',
      status: status,
      tariffType: tariff_type
    })
  })
}

export function mapToMetrics ({ fast, slow, used_cards_amount }) {
  return {
    fast,
    slow,
    cardsInUse: used_cards_amount * 1 || 0
  }
}
