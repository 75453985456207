<template>
  <div>
    <multiselect
      v-model="value"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :show-labels="false"
      :placeholder="$t('pages.common.search')"
      label="chargingTokenNr"
      track-by="chargingTokenNr"
    >
      <template slot="tag">
        <span />
      </template>
      <template slot="caret">
        <span />
      </template>
      <template slot="beforeList">
        <div class="select-button-box">
          <div class="multiselect__option select-all checkbox-label" @click="selectAll()">
            <input type="checkbox" :checked="allSelected" @focus.prevent>
            {{ $t('pages.common.selectAll') }}
          </div>
        </div>
      </template>
      <template slot="placeholder">
        <div class="placeholder-wrapper">
          <div class="placeholder-icon">
            <i class="fa fa-credit-card" />
          </div>
          <div class="placeholder-text">
            <span class="placeholder">{{ $t('mobility.selectCards') }}</span>
          </div>
        </div>
      </template>
      <template slot="option" slot-scope="scope">
        <div class="checkbox-label" @click="debouncedFunction(scope.option)">
          <input :key="scope.option.chargingTokenNr" type="checkbox" :checked="scope.option.selected">
          {{ scope.option.chargingTokenNr }}
        </div>
      </template>
      <template slot="beforeList">
        <span class="popover-caret" />
      </template>
      <template slot="noResult">
        <span class="multiselect__single no-results">
          {{ $t('pages.common.noResults') }}
        </span>
      </template>
      <template
        slot="selection"
        slot-scope="{values, isOpen}"
      >
        <span v-if="values.length &amp;&amp; !isOpen" class="multiselect__single">
          <div class="multiselect__single-wrapper">
            <div class="multiselect__single-icon">
              <i class="fa fa-credit-card" />
            </div>
            <div class="multiselect__single-text">
              <span class="placeholder">{{ values.length }} {{ values.length > 1 ? $t('mobility.cards') : $t('mobility.card') }}</span>
            </div>
          </div>
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapMutations, mapState } from 'vuex'
import _ from 'lodash-es'

export default {
  components: {
    Multiselect
  },
  data () {
    return {
      value: [],
      options: [],
      debouncedFunction: Function
    }
  },
  computed: {
    ...mapState('tokens', ['tokens']),
    allSelected () {
      return this.options.every(el => el.selected)
    }
  },
  watch: {
    tokens: {
      immediate: true,
      handler () {
        this.options = this.tokens ? this.tokens.map(el => {
          el.selected = false
          return el
        }) : []
        this.selectAll()
      }
    }
  },
  created () {
    this.debouncedFunction = _.debounce((e) => {
      // we cant assign debounce as a method, it wont work in case of multiply components in use
      this.select(e)
    }, 500)
  },
  methods: {
    ...mapMutations('filtering', [
      'addTokenFilter',
      'removeTokenFilter',
      'clearTokenFilters'
    ]),
    selectAll () {
      this.clearTokenFilters()
      this.options.forEach(el => {
        el.selected = !this.allSelected
      })
      if (!this.allSelected) {
        this.options.forEach(el => {
          this.addTokenFilter(el)
        })
        this.value = [...this.options]
      } else {
        this.value = [...this.options.filter(el => el.selected)]
      }
      // for update components state to show number of cards in preview
      this.options = [...this.options]
      // for trigger the computed property change
    },
    select (option) {
      option.selected = !option.selected
      option.selected ? this.addTokenFilter(option) : this.removeTokenFilter(option)
      this.options = [...this.options]
      // for trigger the computed property change
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '../../styles/app';

.multiselect {
  cursor: pointer;
  min-height: 36px;
  color: $navbar-link-color;

  @include media-breakpoint-up(md) {
    width: 220px;
  }

  .multiselect__placeholder {
    display: block;
    color: $navbar-link-color;
    margin-bottom: 0px;

    .placeholder {
      &-wrapper {
        display: flex;
        align-content: stretch;
        align-items: baseline;
        justify-content: center;
      }

      &-icon {
        margin-top: 1px;
      }

      &-text {
        width: 100%;
        text-align: center;
        font-size: 14px;
      }
    }
  }

  .no-results {
    text-align: center;
  }

  .multiselect__option {
    padding: 0;
  }

  .multiselect__input {
    line-height: 1;
    padding: 1px 0 0 5px;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 14px;
    font-family: var(--label-font);
  }

  .checkbox-label {
    padding: 12px;

    input {
      margin-right: 5px;
    }
  }

  .multiselect__single {
    padding-left: 5px;
    margin-bottom: 1px;

    &-wrapper {
      display: flex;
      align-content: stretch;
      align-items: baseline;
      justify-content: center;
    }

    &-icon {
      margin-top: 1px;

      i {
        font-size: 14px;
        margin-left: -4px;
      }
    }

    &-text {
      width: 100%;
      text-align: center;
      font-size: 14px;
    }
  }

  .multiselect__tags {
    padding: 3px 20px 5.5px 15px;
    border-radius: 20px !important;
    border-color: var(--filters-border-color);
    min-height: 36px;

    .multiselect__single {
      margin-top: 2px;
    }

    i {
      color: var(--filters-icon-color);
      margin-right: 10px;
    }
  }

  .multiselect__option--highlight, .multiselect__option--selected {
    background-color: inherit;
    color: inherit;
    font-weight: normal;
  }

  .multiselect__content-wrapper {
    transform: translate3d(20px, 10px, 0px);
    will-change: transform;
    z-index: 10;
    animation: all 0.8s ease-in-out;
    border: var(--content-border);
    border-radius: 5px;
    overflow: auto;
  }

  .popover-caret {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: rgb(255, 255, 255);
    background-image: none;
    background-origin: padding-box;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    border-left-color: var(--filters-border-color);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-color: var(--filters-border-color);
    border-top-style: solid;
    border-top-width: 1px;
    box-sizing: border-box;
    color: rgb(26, 32, 44);
    content: "";
    display: block;
    font-family: var(--label-font);
    font-size: 14px;
    font-weight: 300;
    height: 12px;
    left: 18px;
    line-height: 21px;
    position: absolute;
    text-align: left;
    text-size-adjust: 100%;
    top: 0px;
    transform: matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, -6);
    user-select: auto;
    width: 12px;
    z-index: -1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
</style>
