<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center mx-1 mb-2">
      <h4 class="font-weight-normal">
        {{ title }}
      </h4>
      <export-to-excel-button :loading="excelLoading" @click="export2Excel" />
    </b-row>
    <b-row>
      <b-col xs="12">
        <div class="pb-xlg h-100">
          <div class="table-responsive">
            <v-server-table
              ref="table"
              :columns="columns"
              :options="options"
            />
            <div v-if="loading" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
              <b-spinner class="fetching-indicator" type="grow" label="Spinning" />
            </div>
            <b-button
              v-if="showNextPage"
              class="center load-more-button"
              @click="loadAdditionalData()"
            >
              {{ $t('pages.common.loadMore') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ExportToExcelButton from '@/components/ExportToExcelButton/ExportToExcelButton'

export default {
  name: 'TransactionsTable',
  components: {
    ExportToExcelButton
  },
  props: ['title'],
  data () {
    return {
      columns: [
        'startTime',
        'duration',
        'formattedEnergy',
        'tariffType',
        'chargingPointNr',
        'chargingTokenNr',
        'address',
        'city',
        'country'],
      sortable: ['startTime', 'duration', 'formattedEnergy', 'chargingPointNr', 'chargingTokenNr', 'address', 'city', 'country'],
      transactions: [],
      linesCount: 25,
      loading: false,
      excelLoading: false,
      showNextPage: false,
      tokens: []
    }
  },
  computed: {
    ...mapState('filtering', ['dateFilter', 'tokenFilters']),
    ...mapGetters('filtering', ['getDateFilter']),
    options () {
      return {
        // startTime should be first, initial dates logic rely to this order
        headings: {
          startTime: this.$t('pages.emobility.sections.transactions.columns.startTime'),
          duration: this.$t('pages.emobility.sections.transactions.columns.duration'),
          formattedEnergy: this.$t('pages.emobility.sections.transactions.columns.kWh'),
          tariffType: this.$t('pages.emobility.sections.transactions.columns.tariff'),
          chargingPointNr: this.$t('pages.emobility.sections.transactions.columns.chargingPointNr'),
          chargingTokenNr: this.$t('pages.emobility.sections.transactions.columns.chargingTokenNr'),
          address: this.$t('pages.emobility.sections.transactions.columns.address'),
          city: this.$t('pages.emobility.sections.transactions.columns.city'),
          country: this.$t('pages.emobility.sections.transactions.columns.country')
        },
        columnsDisplay: {
          chargingTokenNr: 'min_desktop',
          chargingPointNr: 'min_desktop',
          address: 'min_desktop',
          city: 'min_desktop',
          country: 'min_desktop'
        },
        sortable: this.sortable,
        orderBy: {
          column: 1,
          ascending: false
        },
        filterable: false,
        pagination: { chunk: 25, dropdown: false },
        perPageValues: [],
        perPage: 1000,
        // this parameter we need for hide dafault pagination panel
        texts: {
          limit: '',
          count: '',
          noResults: this.$t('pages.common.noResults'),
          loading: this.$t('pages.common.loading')
        },
        skin: 'table',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        },
        requestAdapter: data => {
          return {
            page: data.page - 1,
            size: this.linesCount,
            sort: {
              sortBy: this.getSortByForColumn(data.orderBy),
              sortingDir: data.ascending
            }
          }
        },
        requestFunction: this.loadTransactions
      }
    }
  },
  watch: {
    dateFilter: {
      handler () {
        // eslint-disable-next-line no-console
        console.log()
        // I dont know why but without this console the request triggers twise
        if (this.$refs.table) {
          this.$refs.table.refresh()
        }
      }
    },
    tokenFilters: {
      handler () {
        if (this.$refs.table) {
          this.$refs.table.refresh()
        }
      }
    }
  },
  methods: {
    getSortByForColumn (column) {
      switch (this.columns.indexOf(column)) {
        default:
        case 0:
          return 'start_date'
        case 1:
          return 'duration'
        case 2:
          return 'energy'
        case 3:
          return 'tariff_type'
        case 4:
          return 'connector__evse__id'
        case 5:
          return 'card__visible_number'
        case 6:
          return 'meta__original__location__address'
        case 7:
          return 'meta__original__location__city'
        case 8:
          return 'meta__original__location__country'
      }
    },
    loadTransactions (data) {
      const emptyResponse = {
        count: 0,
        data: []
      }
      if (this.loading || (!this.getDateFilter.startDate && !this.getDateFilter.stopDate)) {
        return Promise.resolve(emptyResponse)
      }
      this.loading = true
      const tokens = this.tokenFilters.map(el => el.chargingTokenNr)
      return this.$transactionService.getTransactions({
        ...this.getDateFilter,
        tokens,
        paging: data
      }).then(res => {
        this.transactions = res.data
        this.showNextPage = res.hasNextPage
        res.count = this.transactions.length || 0
        // res.count should mutate here for avoid showing default pagination panel
        return res
        // eslint-disable-next-line no-console
      }).catch(err => {
        console.error(err)
        return emptyResponse
      })
        .finally(() => (this.loading = false))
    },
    loadAdditionalData () {
      this.linesCount += 25
      this.$refs.table.refresh()
    },
    export2Excel () {
      const filters = {}
      if (this.getDateFilter.startDate) {
        filters['start_date__date__gte'] = this.getDateFilter.startDate.split('T')[0]
      }
      if (this.getDateFilter.stopDate) {
        filters['start_date__date__lte'] = this.getDateFilter.stopDate.split('T')[0]
      }
      filters['status__in'] = ['approved', 'final']
      const options = {
        model: 'chargedetailrecord',
        fileName: `${this.$t('mobility.cdrs')}.xlsx`,
        fields: [
          'start_date',
          'duration',
          'energy',
          'tariff_type',
          'connector.evse.id',
          'card.visible_number',
          'location.address',
          'location.city',
          'location.country.iso2'
        ],
        headings: [
          this.$t('pages.emobility.sections.transactions.columns.startTime'),
          this.$t('pages.emobility.sections.transactions.columns.duration'),
          this.$t('pages.emobility.sections.transactions.columns.kWh'),
          this.$t('pages.emobility.sections.transactions.columns.tariff'),
          this.$t('pages.emobility.sections.transactions.columns.chargingPointNr'),
          this.$t('pages.emobility.sections.transactions.columns.chargingTokenNr'),
          this.$t('pages.emobility.sections.transactions.columns.address'),
          this.$t('pages.emobility.sections.transactions.columns.city'),
          this.$t('pages.emobility.sections.transactions.columns.country')
        ],
        filters
      }
      this.excelLoading = true
      this.$exportService.exportToExcel(options)
        .finally(() => (this.excelLoading = false))
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../styles/app';
  h4 {
    margin-bottom: 0;
  }
</style>
