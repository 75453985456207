<template>
  <section>
    <b-row class="d-flex align-items-end align-self-end">
      <b-col cols="6" lg="5" xl="4">
        <AnimatedTile
          value-format="0"
          :value="value1"
          :title="$t('widgets.chargingCosts.sessions')"
        />
      </b-col>
      <b-col cols="6" lg="5" xl="4">
        <AnimatedTile
          value-format="0.00"
          :value="value2"
          :title="$t('widgets.chargingCosts.total')"
        />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-end align-self-end">
      <b-col cols="6" lg="5" xl="4">
        <AnimatedTile
          value-format="0.00"
          :value="value2"
          :title="$t('widgets.chargingCosts.normalCharging')"
        />
      </b-col>
      <b-col cols="6" lg="5" xl="4">
        <AnimatedTile
          value-format="0.00"
          :value="value4"
          :title="$t('widgets.chargingCosts.fastCharging')"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import AnimatedTile from '@/components/AnimatedTile/AnimatedTile'

export default {
  name: 'ChargingCosts',
  components: { AnimatedTile },
  data: () => ({
    value1: 4,
    value2: 138,
    value3: 52,
    value4: 86
  })
}
</script>
