/* eslint-disable camelcase */
export class ChargingToken {
  constructor ({
    chargingTokenNr,
    type,
    issuer,
    status,
    hiddenId,
    totalAmount,
    usedAmount,
    leftAmount,
    isPromotion
  }) {
    Object.assign(this, {
      chargingTokenNr,
      type,
      issuer,
      status,
      hiddenId,
      totalAmount,
      usedAmount,
      leftAmount,
      isPromotion
    })
  }
}

export function mapToChargingTokens (chargingTokenResponse) {
  return chargingTokenResponse?.data?.results?.map(({
    visible_number,
    hidden_number,
    state,
    type,
    issuer,
    promotional_credit
  }) => {
    return new ChargingToken({
      chargingTokenNr: visible_number,
      type,
      issuer,
      status: state,
      hiddenId: hidden_number,
      totalAmount: promotional_credit.total_amount,
      usedAmount: promotional_credit.used_amount,
      leftAmount: promotional_credit.amount_left,
      isPromotion: !!promotional_credit.total_amount
    })
  })
}
