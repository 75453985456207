<template>
  <section>
    <b-row class="d-flex align-items-end align-self-end">
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="getActiveTokenFiltersCount"
          :title="$tc('widgets.chargingSessions.activeTokens', getActiveTokenFiltersCount || '0')"
        />
      </b-col>
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="metrics.cardsInUse"
          :title="$tc('widgets.chargingSessions.cardsInUse', metrics.cardsInUse || '0')"
        />
      </b-col>
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="sessions"
          :title="$tc('widgets.chargingSessions.cdrs', sessions || '0')"
        />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-end align-self-end">
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="metrics.slow.count"
          :title="$t('widgets.chargingSessions.normalCharging')"
        />
      </b-col>
      <b-col cols="4">
        <AnimatedTile
          value-format="0"
          :value="metrics.fast.count"
          :title="$t('widgets.chargingSessions.fastCharging')"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import AnimatedTile from '@/components/AnimatedTile/AnimatedTile'

export default {
  name: 'ChargingSessionsWidget',
  components: { AnimatedTile },
  props: {
    metrics: {
      fast: {
        count: {
          type: Number
        },
        energy: {
          type: Number
        }
      },
      slow: {
        count: {
          type: Number
        },
        energy: {
          type: Number
        }
      },
      cardsInUse: Number
    }
  },
  computed: {
    ...mapGetters('filtering', ['getActiveTokenFiltersCount']),
    sessions () {
      return this.metrics.slow.count + this.metrics.fast.count
    }
  }
}
</script>
