/* eslint-disable camelcase */
import { AbstractService } from '../abstract'
import { mapToInstallations } from '@/business/frontend/Installations'

class InstallationsService extends AbstractService {
  getInstallations (params) {
    return this.getData(({ paging }) => {
      const { sortingDir, sortBy, page, size } = paging
      const sort = sortBy ? `${sortingDir === 0 ? '-' : ''}${sortBy}` : ''
      return this.http.get('/installations/', {
        params: {
          limit: page ? page * size : size,
          offset: page ? size : 0,
          sort
        }
      }).then(response => {
        const data = response.data
        const results = data.results && data.results.length ? data.results.map(mapToInstallations) : []
        return {
          data: results,
          hasNextPage: !!data.next
        }
      }).catch(err => {
        console.error(err)
        throw err
      })
    }, params)
  }
}

export const installationsService = new InstallationsService({ baseURL: '/mobility2/secured' })
