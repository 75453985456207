import Vue from 'vue'
import VueI18n from 'vue-i18n'
import get from 'lodash-es/get'

import en from '@/i18n/translations/en'
import nl from '@/i18n/translations/nl'

// Temp disable German translations
// import de from '@/i18n/translations/de';

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_MODE === 'dev' ? 'en' : 'nl',
  fallbackLocale: 'en',
  messages: { en, nl }
})

export const getMessageForKey = key => {
  const message = get(i18n.messages[i18n.locale], key)
  if (message) return message
  return key
}
