<template>
  <div class="error-page">
    <b-container>
      <div class="errorContainer mx-auto">
        <h1 class="errorCode">
          404
        </h1>
        <p class="errorInfo">
          Opps, it seems that this page does not exist.
        </p>
        <p class="errorHelp mb-3">
          Return to previous page
        </p>
        <a @click="$router.go(-1)">
          <b-button class="errorBtn" type="submit" variant="inverse">
            Return
          </b-button>
        </a>
      </div>
      <footer class="pageFooter">
        {{ title }}
      </footer>
    </b-container>
  </div>
</template>

<script>
import {
  getNameForCurrentLabel
} from '@/utils/branding-helper'

export default {
  name: 'ErrorPage',
  data () {
    return {
      title: `${this.$t(getNameForCurrentLabel())}`
    }
  }
}
</script>

<style src="./Error.scss" lang="scss" scoped />
