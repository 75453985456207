export default {
  yes: 'yes',
  no: 'no',
  lastName: 'Last Name',
  banner: {
    title: 'See your sessions in our new portal',
    description: 'In My Coolblue, just like in the old portal, you can see all your charging sessions, view all your invoices and add a charging card. With the following steps, you log in easily.',
    firstPoint: 'Go to My Coolblue by clicking the green button.',
    secondPoint: 'Log in with the email address at which you receive our invoices.\nDo you not have a Coolblue account with that email address yet? Create one first. It can take up to 24 hours before you can see your charging sessions.',
    info: `Can't figure it out? Then feel free to `,
    link: 'contact us.',
    btnText: 'Go to My Coolblue'
  },
  widgets: {
    chargingSessions: {
      activeTokens: 'Active card | Active cards',
      sessions: 'Sessions',
      cdrs: 'Transaction | Transactions',
      cardsInUse: 'Card in use | Cards in use',
      normalCharging: 'Normal charging',
      fastCharging: 'Fast charging'
    },
    chargingCosts: {
      sessions: 'Sessions',
      total: 'Total €',
      normalCharging: '€ Normal charging',
      fastCharging: '€ Fast charging'
    },
    consumption: {
      activeTokens: 'Active card | Active cards',
      total: 'Total kWh',
      normalCharging: 'kWh Normal charging',
      fastCharging: 'kWh Fast charging'
    }
  },
  mobility: {
    card: 'Card',
    cards: 'Cards',
    cdrs: 'Transactions',
    selectCards: 'Select Cards',
    chargingSessions: 'Sessions charging stations',
    chargingCdrs: 'Transactions charging cards',
    chargingCosts: 'Charging Costs',
    chargePoints: 'Charging stations',
    chargingCards: 'Charging cards',
    consumption: 'Consumption in kWh',
    invoices: 'Invoices',
    dateRange: 'Start date - End date'
  },
  sideOperations: {
    title: 'Costs Parking and PT',
    type: 'Type',
    cardNumber: 'Card Number',
    startsOn: 'Date and Time',
    startLocation: 'Location',
    declarationText: 'Description',
    rawCostsExVat: 'Costs excl. vat'
  },
  leads: {
    title: 'Leads',
    dealerLocation: 'Dealership Location',
    advisorName: 'Lease Advisor Name',
    leadName: 'Lead Name',
    contractNumber: 'Contract Number',
    buyOrLease: 'Buy or Lease',
    createdAt: 'New',
    inspectionFormSent: 'Inspection Form Sent',
    inspectionFormReceived: 'Inspection Details Received',
    offerSent: 'Quote Sent',
    status: 'Status',
    contractDate: 'Contract Date',
    productChoice: 'Product Choice',
    submit: 'Submit Lead',
    statuses: {
      title: 'Status',
      non: 'Not selected',
      inProgress: 'In progress',
      won: 'Won',
      lost: 'Lost'
    },
    types: {
      buy: 'Buy',
      lease: 'Lease'
    },
    products: {
      none: 'None',
      chargePoint: 'Only Charge Point',
      card: 'Only Card',
      chargePointAndCard: 'Charge Point And Card'
    }
  },
  cardLeads: {
    title: 'Chargecard Leads',
    requestDate: 'Request Date',
    registrationNumber: 'Registration Number',
    name: 'Name',
    company: 'Company',
    phoneNumber: 'Phone Number',
    email: 'E-mail',
    statuses: {
      requested: 'Requested',
      sent: 'Sent to customer',
      activated: 'Activated'
    }
  },
  installations: {
    title: 'Installations',
    dealerLocation: 'Dealership Location',
    advisorName: 'Lease Advisor Name',
    driverName: 'Driver Name',
    contractNumber: 'Contract Number',
    contractDate: 'Contract Start Date',
    cpDeliveryDateCommunicated: 'Delivery Date Communicated',
    cpDeliveryDate: 'Delivery Date',
    cpOrdered: 'Charge Point Ordered',
    cpInstallationDate: 'Charge Point Installation Date'
  },
  pages: {
    common: {
      welcomeMessage: 'Welcome',
      welcomeMessageName: 'Welcome, {givenName}!',
      logout: 'Log Out',
      exportToExcel: 'Export to Excel',
      noResults: 'No matching results',
      loading: 'Loading',
      portal: 'Portal',
      loadMore: 'Load More',
      selectAll: 'Select All',
      search: 'Search',
      backToMobility: 'Back to My eMobility',
      impersonatorText: 'You are using this as an impersonator',
      sentByEmail: 'As the size of the export file is large, it will be sent to the email address of this account.'
    },
    emobility: {
      title: 'My eMobility',
      errors: {
        loading: {
          general: 'Error occurred while loading data from the server',
          transactions: 'Error occurred while loading sessions',
          chargingTokens: 'Error occurred while loading charging cards',
          chargePoints: 'Error occurred while loading charge points',
          invoices: 'Error occurred while loading invoices'
        },
        downloading: 'Error occurred while downloading the file from the server',
        sessionExpired: 'Your session is expired because you were idle for more than 30 minutes'
      },
      sections: {
        dashboard: {
          title: 'Dashboard',
          tabs: {
            transactions: {
              title: 'Sessions',
              sessions: 'Sessions'
            },
            kwh: {
              title: 'kWh',
              totalAmount: 'Total',
              unit: 'kWh'
            },
            common: {
              activeTokens: 'Active cards',
              acCharging: 'AC charging',
              acChargingShort: 'AC',
              dcCharging: 'DC (fast) charging',
              dcChargingShort: 'DC (fast)'
            }
          },
          filtering: {
            title: 'Filter by',
            byDate: {
              title: 'Filter by date',
              noFilter: 'No filters'
            },
            byToken: {
              title: 'Filter by card',
              placeholder: 'Select a charging card...',
              noFilter: 'No filters applied',
              zeroToken: 'You don\'t have any charging cards.',
              oneToken: 'You have only one charging card.'
            }
          }
        },
        transactions: {
          title: 'Sessions',
          columns: {
            startTime: 'Start time',
            duration: 'Duration',
            kWh: 'kWh',
            chargingPointNr: 'Charging station ID',
            chargingTokenNr: 'Charging card ID',
            address: 'Address',
            postalCode: 'Postal code',
            city: 'City',
            country: 'Country',
            status: 'Status',
            tariff: 'Tariff Type'
          },
          status: {
            inProgress: 'In progress',
            approved: 'Approved'
          }
        },
        chargingTokens: {
          title: 'Charging cards',
          columns: {
            chargingTokenNr: 'Charging card ID',
            type: 'Type',
            userName: 'User name',
            status: 'Status',
            totalAmount: 'Total Amount',
            usedAmount: 'Used Amount',
            leftAmount: 'Remaining Credit',
            isPromotion: 'Promotion card'
          },
          types: {
            chargingCard: 'Charging card',
            keyChain: 'Charging keycard'
          },
          statuses: {
            inactive: 'Inactive',
            allocated: 'Allocated',
            delivered: 'Delivered',
            active: 'Active',
            blocked: 'Blocked',
            ordered: 'Ordered',
            in_stock: 'In Stock'
          }
        },
        invoices: {
          title: 'Invoices',
          columns: {
            invoiceNumber: 'Invoice number',
            invoicePeriod: 'Invoice period',
            taxExclusiveAmount: 'Invoice amount (excl. VAT)',
            taxAmount: 'VAT',
            taxInclusiveAmount: 'Invoice amount (incl. VAT)',
            download: 'Download',
            reversed: 'Reversed',
            credit: 'Credit'
          }
        }
      }
    },
    auth: {
      email: 'E-mail',
      username: 'Username',
      password: 'Password',
      verificationCode: 'Verification code',
      confirmPassword: 'Confirm password',
      resetPassword: 'Reset password',
      login: {
        title: 'Welcome',
        tip: 'Here you can log in with your username and password',
        forgotPassword: 'Forgot password',
        submit: 'LOGIN'
      },
      'forgot-password': {
        title: 'Forgot password',
        tip: 'Please enter your username',
        submit: 'SUBMIT',
        successMessage: 'Password reset email has been sent, please check your mailbox'
      },
      'activate-account': {
        title: 'Activate account',
        tip: 'Please enter your username',
        submit: 'ACTIVATE',
        successMessage: 'Activate reset email has been sent, please check your mailbox'
      },
      'reset-password': {
        title: 'Create password',
        tip: 'Please enter the password reset verification code you received via email!',
        submit: 'CREATE NEW PASSWORD',
        successMessage: 'Password reset successful, now you can log in'
      },
      'complete-password': {
        title: 'New password',
        tip: 'Please provide a new password before your first login',
        successMessage: 'Password change successful, now you can log in',
        submit: 'SET PASSWORD'
      },
      errors: {
        usernamePasswordIncorrect: 'Username and password are incorrect!',
        passwordsMismatch: 'Passwords must match!',
        wrongReferrer: "You can't login as impersonator. To login as an impersonator you need to start impersonation at Multiforce."
      }
    },
    mySettings: {
      title: 'My settings',
      myDetails: 'My details',
      adjust: 'Adjust',
      account: {
        title: 'Account',
        name: 'Name'
      },
      contactDetails: {
        title: 'Contact details',
        phone: 'Phone',
        website: 'Website',
        accountNumber: 'Account number'
      },
      language: 'Language'
    }
  },
  labels: {
    greennl: 'GreenNL',
    ponenergie: 'PonEnergie',
    shuttel: 'Shuttel',
    porsche: 'Porsche',
    dutchlease: 'DutchLease',
    easyopweg: 'e-asy op weg',
    eenergie: 'E-Energie',
    bgreen: '2B Green',
    epouwer: 'e-Pouwer',
    mobilitics: 'Mobilitics',
    enie: 'Enie',
    cube: 'Cube',
    pondealer: 'Pon Dealer',
    coolblue: 'Coolblue',
    brandstof: 'Brandstof',
    allurecharge: 'AllureCharge',
    hoogenboom: 'Powered by Hoogenboom',
    laadkracht: 'Laadkracht',
    h2e: 'H2E Power',
    apoint: 'A-Point'
  },
  languages: {
    en: 'English',
    nl: 'Nederlands'
    // de: 'Deutsch'
  }
}
