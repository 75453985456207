<template>
  <form class="w-100 display-flex flex-column align-items-center" @submit.prevent="resetPassword">
    <div class="form-group w-100">
      <label for="username" class="label">{{ $t('pages.auth.username') }}</label>
      <input
        id="username"
        ref="username"
        class="form-control no-border"
        required
        type="text"
        autocomplete="off"
      >
    </div>

    <div class="form-group w-100">
      <label for="verificationCode" class="label">{{ $t('pages.auth.verificationCode') }}</label>
      <input
        id="verificationCode"
        ref="verificationCode"
        class="form-control no-border"
        required
        type="number"
      >
    </div>

    <div class="form-group w-100">
      <label for="password" class="label">{{ $t('pages.auth.password') }}</label>
      <input
        id="password"
        ref="password"
        class="form-control no-border"
        required
        type="password"
      >
    </div>

    <div class="form-group w-100">
      <label for="confirmPassword" class="label">{{ $t('pages.auth.confirmPassword') }}</label>
      <input
        id="confirmPassword"
        ref="confirmPassword"
        class="form-control no-border"
        required
        type="password"
      >
    </div>

    <b-button
      type="submit"
      size="sm"
      variant="inverse"
      :disabled="inProgress"
    >
      <span>{{ $t('pages.auth.reset-password.submit') }}</span>
      <b-spinner v-if="inProgress" small type="grow" />
      <i v-else class="fa fa-key" />
    </b-button>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { getMessageForKey } from '@/i18n'
import _ from 'lodash'

export default {
  computed: {
    ...mapState('auth', ['inProgress'])
  },
  methods: {
    resetPassword () {
      const formData = _.mapValues(this.$refs, input => input.value)
      const { username, verificationCode, password, confirmPassword } = formData
      if (password !== confirmPassword) {
        this.$messageService.showErrorMessage({
          message: getMessageForKey('pages.auth.errors.passwordsMismatch')
        })
      } else {
        this.$store.dispatch('auth/resetPassword', {
          username: username ? username.toLowerCase() : '',
          code: verificationCode,
          password
        })
      }
    }
  }
}
</script>

<style src="../Auth.scss" lang="scss" scoped/>
